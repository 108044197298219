<template>
  <div>
    <a-modal
      v-model="visible"
      width="750px"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑账号" : "新增账号" }}</div>
      <!-- <div slot="title">新增账号</div>  -->
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }"
        >
          <a-row gutter="12">
            <a-divider orientation="left" id="basic-information">
              基本信息
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="员工编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="员工姓名">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="phone" label="手机号">
                <a-input v-model="form.phone" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="email" label="邮箱">
                <a-input v-model="form.email" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="position" label="住址">
                <a-input v-model="form.position" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="sex" label="性别">
                <a-select v-model="form.sex" style="width: 100%">
                  <a-select-option value="man">男</a-select-option>
                  <a-select-option value="woman">女</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">启用</a-select-option>
                  <a-select-option :value="false">禁用</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="start_date" label="入职时间">
                <a-date-picker
                  v-model="form.start_date"
                  valueFormat="YYYY-MM-DD"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="end_date" label="离职时间">
                <a-date-picker
                  v-model="form.end_date"
                  valueFormat="YYYY-MM-DD"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="identity_number" label="身份证号">
                <a-input v-model="form.identity_number" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="basic-information">
              职位信息
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="employee_type" label="类型">
                <a-select v-model="form.employee_type" style="width: 100%">
                  <a-select-option value="permanent">正式工</a-select-option>
                  <a-select-option value="temporary">临时工</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="salary_month" label="月薪">
                <a-input v-model="form.salary_month" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="salary_day" label="日薪">
                <a-input v-model="form.salary_day" />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="graphic-information">
              档案信息
            </a-divider>
            <a-col :span="24" :md="24">
              <a-upload
                action="/api/employee_documents/"
                list-type="picture-card"
                :headers="{
                  'X-CSRFToken': Cookies.get('csrftoken'),
                  Authorization: `Bearer ${Cookies.get('access')}`,
                }"
                :file-list="form.image_items"
                @preview="handlePreview"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="file"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import { userCreate, userUpdate } from "@/api/hrms.js";

export default {
  name: "FormModal",
  props: ["visible", "form"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      Cookies,
      previewVisible: false,
      previewImage: "",
      rules: {
        username: [
          { required: true, message: "请输入用户名", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        name: [
          { required: true, message: "请输入姓名", trigger: "change" },
          { max: 64, message: "超出最大长度 (64)", trigger: "change" },
        ],
        phone: [{ max: 32, message: "超出最大长度 (32)", trigger: "change" }],
        email: [{ max: 256, message: "超出最大长度 (256)", trigger: "change" }],
        employee_type: [
          { required: true, message: "请选择职位类型", trigger: "change" },
        ],
        sex: [{ required: true, message: "请选择性别", trigger: "change" }],
        salary_month: [
        {
          validator: (rule, value, callback) => {
            if (this.form.employee_type === 'permanent' && !value) {
              callback(new Error('请输入月薪'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ],
      salary_day: [
        {
          validator: (rule, value, callback) => {
            if (this.form.employee_type === 'temporary' && !value) {
              callback(new Error('请输入日薪'));
            } else {
              callback();
            }
          },
          trigger: 'blur'
        }
      ]
      },
      loading: false,
    };
  },
  methods: {
    confirm() {
      let image_items = this.form.image_items.map((item) => {
        return item.response.id;
      });
      let formatData = {
        ...this.form,
        ...{
          documents: image_items,
        },
      };
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id ? userUpdate : userCreate;
          func(formatData)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .catch((err) => {
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },

    async handlePreview(file) {
      if (!file.url && !file.preview) {
        file.preview = await getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.$set(this.form, "image_items", fileList);
    },
    beforeUpload() {},
    handleCancel() {
      this.previewVisible = false;
    },
  },
};
</script>

<style scoped>
</style>