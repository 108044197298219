<template>

  <div>

    <a-card title="生产任务记录">
      <a-row gutter="16">
        <!-- 输入框 -->
        <a-col :span="4"> <!-- 分配更多空间给输入框 -->
          <a-input-search v-model="searchForm.search" placeholder="单号,供应商编号/名称" style="width: 100%;" allowClear
            @search="search" />
        </a-col>

        <!-- 选择框 -->
        <a-col :span="4"> <!-- 调整span的值来分配空间 -->
          <a-select v-model="taskType" @change="changeTask" style="width: 100%;" placeholder="选择任务状态" allowClear>
            <a-select-option value="Analysis">分析</a-select-option>
            <a-select-option value="SamplePurchase">样品采购</a-select-option>
            <a-select-option value="Purchase">物料采购</a-select-option>
            <a-select-option value="InProduction">生产中</a-select-option>
            <a-select-option value="Complete">生产完成</a-select-option>
            <a-select-option value="Shipped">已发货</a-select-option>
            <a-select-option value="Cancel">取消</a-select-option>
          </a-select>
        </a-col>

        <a-col :span="4">
          <a-button @click="downloadExcel">下载数据</a-button>
        </a-col>
      </a-row>


      <a-row style="margin-top: 12px">
        <a-table :columns="columns" :data-source="items" size="small" :pagination="false" :loading="loading"
        :scroll="{ y: tableHeight }">
        <div slot="date" slot-scope="value">{{ moment(value).format('YYYY-MM-DD') }}</div>

        <div slot="action" slot-scope="value, item">
          <a-button-group>
            <a-button type="link" @click="viewTask(item)">编辑</a-button>
            <a-button type="link" @click="viewTaskProcess(item)">生产进度</a-button>
          </a-button-group>

        </div>

      </a-table>
      </a-row>
     
      <div style="text-align: center; margin-top: 16px;">
        <a-pagination v-model="searchForm.page" :total="totalRows" :pageSize="perPage" show-less-items
          @change="changePage" />
      </div>
    </a-card>
  </div>
</template>

<script>
import { productionTaskList } from '@/api/system'
import moment from 'moment'
import * as XLSX from 'xlsx';

export default {
  name: 'WarehouseTask',
  data() {
    return {
      moment,
      taskType: undefined,
      items: [],
      perPage: 16,
      totalRows: 0,
      searchForm: { page: 1, page_size: 10, is_done: false },
      loading: false,
      statusList: [
        {
          id: 1,
          code: 'Analysis',
          name: '分析',
        }, {
          id: 2,
          code: 'SamplePurchase',
          name: '样品采购',
        }, {
          id: 3,
          code: 'Purchase',
          name: '物料采购',
        }, {
          id: 4,
          code: 'InProduction',
          name: '生产中'
        }, {
          id: 5,
          code: 'Complete',
          name: '生产完成'
        }, {
          id: 7,
          code: 'Shipped',
          name: '已发货'
        }, {
          id: 6,
          code: 'Cancel',
          name: '取消'
        }
      ],
      columns: [
        {
          title: '编号',
          dataIndex: 'number',
          key: 'number',
        },
        {
          title: '下单用户',
          dataIndex: 'bm_user_name',
          key: 'bm_user_name',
          // width:'60px'
        },
        {
          title: '店铺',
          dataIndex: 'store_name',
          key: 'store_name',
        },
        {
          title: '状态',
          dataIndex: 'statues',
          key: 'statues',
          customRender: (text) => {
            const statusItem = this.statusList.find(status => status.code === text);
            return statusItem ? statusItem.name : '未知状态';
          }
        },
        {
          title: '产品名称',
          dataIndex: 'product_name',
          key: 'product_name',
        },
        {
          title: '单价',
          dataIndex: 'sale_price_temp',
          key: 'sale_price_temp',
        },
        {
          title: '数量',
          dataIndex: 'quantity',
          key: 'quantity',
          customRender: (value,item) => {
            if(['InProduction','Complete'].includes(item.statues)){
              var quantity_ = item.actual_quantity
            }else if(item.statues=='Shipped'){
              var quantity_ = item.shipment_quantity
            }else{
              var quantity_ = item.quantity
            }
            if(quantity_==null){
              var quantity_ = item.quantity
            }
            return quantity_;
          }
        },
        {
          title: '金额',
          dataIndex: 'total_amount',
          key: 'total_amount',
        },
        {
          title: '更新时间',
          dataIndex: 'update_time',
          key: 'update_time',
          // width:'110px'
        },
        {
          title: '创建时间',
          dataIndex: 'create_time',
          key: 'create_time',
        },
        {
          title: '用户备注',
          dataIndex: 'remark',
          key: 'remark',
        }, {
          title: '操作',
          dataIndex: 'action',
          scopedSlots: { customRender: 'action' },
        },
      ],
      tableHeight: window.innerHeight
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      this.searchForm['statues'] = this.taskType
      productionTaskList(this.searchForm).then(resp => {
        this.totalRows = resp.count;
        this.items = resp.results;
      }).finally(() => {
        this.loading = false;
      });
    },
    viewTask(item) {
      // this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      this.$router.push({ path: '/production/production_record_detail', query: { search: item.id } });
    },

    viewTaskProcess(item) {
      this.$router.push({ path: '/production/production_task_process', query: { search: item.id } });
    },

    search() {
      this.searchForm.page = 1;
      // this.pagination.current = 1;
      this.list();
    },

    changeTask() {
      this.searchForm.page = 1;
      this.list();
    },
    changePage(value) {
      this.searchForm.page = value;
      this.list();
    },
    downloadExcel() {
      const ws = XLSX.utils.json_to_sheet(this.items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Data.xlsx");
    }
  },
  mounted() {
    this.initialize();
  },
}
</script>

<style scoped></style>
