<template>
  <a-select v-model="selectedItem" placeholder="请选择">
    <a-select-option v-for="item in itemList" :key="item.key" :value="item">
      {{ item.label }}
    </a-select-option>
  </a-select>
</template>
 
<script>
export default {
  data() {
    return {
      selectedItem: null, // 选中的对象
      itemList: [ // 对象列表
        { key: '1', label: '选项1', value: 'Value1' },
        { key: '2', label: '选项2', value: 'Value2' },
        { key: '3', label: '选项3', value: 'Value3' }
      ]
    };
  }
};
</script>