<template>
  <div>
    <a-card title="物料生产任务管理">
      <material-task-table 
      :items="items"
      :loading="loading"
       />
    </a-card>
  </div>
</template>

<script>
import { purchaseOrderList } from "@/api/purchasing";

export default {
  components: {
    MaterialTaskTable:() => import("@/components/MaterialTaskTable/index"),
  },
  data() {
    return {
      loading:false,
      taskType: undefined,
      items: [],
      perPage: 16,
      totalRows: 0,
      searchForm: { page: 1, page_size: 10, is_done: false },
    };
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      this.fitterStatus = 'Analysis,Purchase,InProduction';
      if(this.taskType){
        this.searchForm["status"] = this.taskType;
      }else{
        this.searchForm["status"] = this.fitterStatus; 
      }
     
      
      // this.searchForm["supplier"] = 41;
      this.searchForm["is_produce"] = true;
      purchaseOrderList(this.searchForm)
        .then((resp) => {
          this.totalRows = resp.count;
          // this.items = resp.results;
          this.items = this.process_data(resp.results);
        })
        .finally(() => {
          this.loading = false;
        });
    },

    process_data(data) {
      const updatedData = data.map((item) => {
        // 获取purchase_materials_items中的第一个元素
        const purchaseItem = item.purchase_materials_items[0] || {};

        // 合并数据
        const updatedItem = {
          ...item,
          materials: purchaseItem.materials,
          materials_name: purchaseItem.materials_name,
          materials_number: purchaseItem.materials_number,
          purchase_quantity: purchaseItem.purchase_quantity,
          total_amount: purchaseItem.total_amount,
        };

        // 删除purchase_materials_items
        delete updatedItem.purchase_materials_items;

        return updatedItem;
      });
      return updatedData;
    },

    search() {
      this.searchForm.page = 1;
      // this.pagination.current = 1;
      this.list();
    },

    changeTask() {
      this.searchForm.page = 1;
      this.list();
    },
    changePage(value) {
      this.searchForm.page = value;
      this.list();
    },
    downloadExcel() {
      const ws = XLSX.utils.json_to_sheet(this.items);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Sheet1");

      XLSX.writeFile(wb, "Data.xlsx");
    },
  },
  mounted() {
    this.initialize();
  },

};
</script>

<style scoped>

</style>