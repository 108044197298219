export default {
  path: '/hrms',
  name: 'hrms',
  component: () => import('@/layouts/BaseLayout'),
  children: [
    {
      path: 'employee',
      name: 'employee',
      meta: { title: '员工管理', permission: 'employee' },
      component: () => import('@/views/hrms/employee/index'),
    },
    {
      path: 'work_records',
      name: 'work_records',
      meta: { title: '工时管理', permission: 'work_records' },
      component: () => import('@/views/hrms/work_records'),
    },
    {
      path: 'salary_records',
      name: 'salary_records',
      meta: { title: '薪资管理', permission: 'salary_records' },
      component: () => import('@/views/hrms/salary_records'),
    },
    {
      path: 'tsalary_records',
      name: 'tsalary_records',
      meta: { title: '薪资管理', permission: 'salary_records' },
      component: () => import('@/views/hrms/paymentSalary'),
    },
    {
      path: 'payment_salary_view',
      meta: { title: '结算单概览', permission: 'salary_records' },
      component: () => import('@/views/hrms/paymentSalary/view'),
    },
    {
      path: 'payment_salary_create',
      meta: { title: '创建结算单', permission: 'salary_records' },
      component: () => import('@/views/hrms/paymentSalary/create'),
    },
    {
      path: 'payment_salary_p_create',
      meta: { title: '正式工创建结算单', permission: 'salary_records' },
      component: () => import('@/views/hrms/paymentSalary/p_create'),
    },
  ],
}