import request from '@/utils/request';


// User
export function userList(params) {
  return request({ url: `/employees/`, method: 'get', params })
}

export function userCreate(data) {
  return request({ url: `/employees/`, method: 'post', data })
}

export function userUpdate(data) {
  return request({ url: `/employees/${data.id}/`, method: 'put', data })
}
export function salaryUnPaid(params) {
  return request({ url: `/employees/non_payment/`, method: 'get', params })
}


// work_records
export function workRecordsList(params) {
  return request({ url: `/work_records/`, method: 'get', params })
}

export function workRecordsCreate(data) {
  return request({ url: `/work_records/`, method: 'post', data })
}

export function workRecordsUpdate(data) {
  return request({ url: `/work_records/${data.id}/`, method: 'put', data })
}

export function workRecordsDestroy(data) {
  return request({ url: `/work_records/${data.id}/`, method: 'delete', data })
}

// salary_records
export function salaryRecordsList(params) {
  return request({ url: `/salary_records/`, method: 'get', params })
}

export function salaryRecordsCreate(data) {
  return request({ url: `/salary_records/`, method: 'post', data })
}
export function SalaryRecordVoid(data) {
  return request({ url: `/salary_records/${data.id}/void/`, method: 'post', data })
}

export function salaryRecordsUpdate(data) {
  return request({ url: `/salary_records/${data.id}/`, method: 'put', data })
}
export function unpaidWorkList(params) {
  return request({ url: `/work_records/unpaid_work/`, method: 'get', params })
}
// salary_records

export function getSalaryRecordsNumber(params) {
  return request({ url: `/salary_records/number/`, method: 'get', params })
}



