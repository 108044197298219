var render = function () {
var this$1 = this;
var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('a-card',{attrs:{"title":"薪资结算单创建"}},[_c('a-button',{attrs:{"slot":"extra","type":"primary","ghost":""},on:{"click":function () { this$1.$router.go(-1); }},slot:"extra"},[_c('a-icon',{attrs:{"type":"left"}}),_vm._v("返回")],1),_c('a-spin',{attrs:{"spinning":_vm.loading}},[_c('a-form-model',{ref:"form",attrs:{"model":_vm.form,"rules":_vm.rules,"label-col":{ span: 8 },"wrapper-col":{ span: 16 }}},[_c('a-row',[_c('a-col',{staticStyle:{"width":"320px"},attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"prop":"number","label":"结算编号"}},[_c('a-input',{model:{value:(_vm.form.number),callback:function ($$v) {_vm.$set(_vm.form, "number", $$v)},expression:"form.number"}})],1)],1),_c('a-col',{staticStyle:{"width":"320px"},attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"prop":"employee","label":"员工"}},[_c('a-input',{staticStyle:{"font-weight":"bold"},attrs:{"value":_vm.employee.name,"disabled":"true"}})],1)],1),_c('a-col',{staticStyle:{"width":"320px"},attrs:{"span":6}},[_c('a-form-model-item',{attrs:{"prop":"remark","label":"备注"}},[_c('a-input',{attrs:{"allowClear":""},model:{value:(_vm.form.remark),callback:function ($$v) {_vm.$set(_vm.form, "remark", $$v)},expression:"form.remark"}})],1)],1)],1)],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("未结算考勤")]),_c('a-button',{attrs:{"type":"primary"},on:{"click":_vm.downloadOrders}},[_vm._v("下载信息")]),_c('div',[_c('div',{staticStyle:{"margin-top":"16px"}},[_c('a-table',{attrs:{"rowKey":"id","size":"middle","columns":_vm.columns,"loading":_vm.tb_loading,"data-source":_vm.productsData,"pagination":false,"row-selection":{
              selectedRowKeys: _vm.selectedRowKeys,
              onChange: _vm.onSelectChange,
            },"scroll":{ y: '400px' }}})],1)]),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("账单信息")]),_c('div',[_c('a-row',{attrs:{"gutter":"16"}},[_c('a-col',{attrs:{"span":4}},[_c('a-form-model-item',{attrs:{"prop":"other_amount","label":"其他费用","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input-number',{staticStyle:{"width":"100%"},model:{value:(_vm.form.other_amount),callback:function ($$v) {_vm.$set(_vm.form, "other_amount", $$v)},expression:"form.other_amount"}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-model-item',{attrs:{"label":"总计金额(元)","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input-number',{staticStyle:{"width":"100%"},attrs:{"value":_vm.OrderTotalAmount,"disabled":true}})],1)],1),_c('a-col',{attrs:{"span":4}},[_c('a-form-model-item',{attrs:{"label":"实际支付金额(元)","label-col":{ span: 24 },"wrapper-col":{ span: 24 }}},[_c('a-input-number',{staticStyle:{"width":"100%"},model:{value:(_vm.collection_amount),callback:function ($$v) {_vm.collection_amount=$$v},expression:"collection_amount"}})],1)],1)],1)],1),_c('a-divider',{attrs:{"orientation":"left"}},[_vm._v("上传付款截图")]),_c('div',[_c('a-col',{attrs:{"span":24,"md":24}},[_c('a-upload',{attrs:{"action":"/api/salary_record_images/","list-type":"picture-card","headers":{
              'X-CSRFToken': _vm.Cookies.get('csrftoken'),
              Authorization: ("Bearer " + (_vm.Cookies.get('access'))),
            },"file-list":_vm.form.image_items,"before-upload":_vm.beforeUpload,"name":"image"},on:{"preview":_vm.handlePreview,"change":_vm.handleChange}},[_c('div',[_c('a-icon',{attrs:{"type":"plus"}}),_c('div',{staticClass:"ant-upload-text"})],1)]),_c('a-modal',{attrs:{"visible":_vm.previewVisible,"footer":null},on:{"cancel":_vm.handleCancel}},[_c('img',{staticStyle:{"width":"100%"},attrs:{"alt":"example","src":_vm.previewImage}})])],1)],1)],1),_c('div',{staticStyle:{"margin-top":"32px"}},[_c('a-popconfirm',{attrs:{"title":"确定创建吗?"},on:{"confirm":_vm.create}},[_c('a-button',{attrs:{"type":"primary","loading":_vm.loading,"disabled":_vm.button_dis}},[_vm._v("批量付款")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }