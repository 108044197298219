export default {
    path: '/demo',
    name: 'demo',
    component: () => import('@/layouts/BaseLayout'),
    children: [
      {
        path: 'demo_1',
        name: 'demo_1',
        meta: { title: 'demo', permission: 'client' },
        component: () => import('@/views/demo/index_1'),
      },
      {
        path: 'demo_2',
        name: 'demo_2',
        meta: { title: 'demo', permission: 'client' },
        component: () => import('@/views/demo/index_2'),
      },
      {
        path: 'demo_3',
        name: 'demo_3',
        meta: { title: 'demo', permission: 'client' },
        component: () => import('@/views/demo/index_2'),
      },
    ],
  }

// export default {
//   path: '/demo',
//   name: 'demo',
//   component: () => import('@/layouts/BaseLayout'),
//   children: [
//     {
//       path: 'demo_:id',  // 使用动态路径参数
//       name: 'demo_detail',
//       meta: { title: 'demo', permission: 'client' },
//       component: route => {
//         // 动态导入对应的视图组件
//         console.log(route.params)
//         const id = route.params.id;
//         return import(`@/views/demo/index_${id}`)
//           ;
//       }
//     },
//   ],
// }
