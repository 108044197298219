<template>
  <div>
    <a-modal
      v-model="visible"
      width="750px"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑物料信息" : "新增物料信息" }}</div>
      <div>
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 4, md: 8 }"
          :wrapper-col="{ span: 20, md: 16 }"
        >
          <a-row gutter="12">
            <a-divider orientation="left" id="basic-information">
              基本信息
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="物料编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="name" label="物料名称">
                <a-input v-model="form.name" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="suggest_name" label="建议物料名称">
                <a-input v-model="suggest_name" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="category" label="分类">
                <a-select
                  v-model.number="form.category"
                  style="width: 100%"
                  :allowClear="true"
                  @change="onCategoryChange"
                  show-search
                  option-filter-prop="children"
                >
                  <a-select-option
                    v-for="item of classificationItems"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="unit" label="单位">
                <a-select v-model.number="form.unit" :allowClear="true">
                  <a-select-option
                    v-for="item of unitItems"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="supplier" label="供应商">
                <a-select
                  v-model.number="form.supplier"
                  :allowClear="true"
                  show-search
                  option-filter-prop="children"
                  @search="handleSearch"
                  :not-found-content="renderNotFoundContent"
                >
                  <a-select-option
                    v-for="item of supplierItems"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.name }}
                  </a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="location" label="位置">
                <a-input v-model="form.location" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="purchase_link" label="购买链接">
                <a-input v-model="form.purchase_link" />
              </a-form-model-item>
            </a-col>

            <a-col :span="24" :md="12">
              <a-form-model-item prop="is_active" label="状态">
                <a-select v-model="form.is_active" style="width: 100%">
                  <a-select-option :value="true">激活</a-select-option>
                  <a-select-option :value="false">冻结</a-select-option>
                </a-select>
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="attr-information">
              属性信息
            </a-divider>

            <!-- 动态生成的表单项放在这里 -->

            <div v-for="attr in activeAttributes" :key="attr.id">
              <a-col :span="24" :md="12">
                <!-- <a-form-model-item :prop="attr.name" :label="attr.name+attr.unit_name"> -->
                <a-form-model-item
                  :prop="attr.name"
                  :label="
                    attr.unit_name
                      ? attr.name + ' ' + attr.unit_name
                      : attr.name
                  "
                >
                  <!-- <a-input
                    v-model="select_attributes[attr.id]"
                    :placeholder="attr.required ? '必填' : '选填'"
                  /> -->
                  <a-select
                    v-model="select_attributes[attr.id]"
                    mode="combobox"
                    show-search
                    :placeholder="attr.required ? '必填' : '选填'"
                    :allowClear="true"
                    option-filter-prop="children"
                    style="width: 200px"
                  >
                    <a-select-option
                      v-for="option in attr.values"
                      :key="option"
                      :value="option"
                    >
                      {{ option }}
                    </a-select-option>
                  </a-select>
                </a-form-model-item>
              </a-col>
            </div>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="graphic-information">
              价格管理
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item prop="purchase_price" label="采购单价(元)">
                <a-input-number
                  v-model="form.purchase_price"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="graphic-information">
              图文信息
            </a-divider>
            <a-col :span="24" :md="24">
              <a-upload
                action="/api/materials_images/"
                list-type="picture-card"
                :headers="{
                  'X-CSRFToken': Cookies.get('csrftoken'),
                  Authorization: `Bearer ${Cookies.get('access')}`,
                }"
                :file-list="form.image_items"
                @preview="handlePreview"
                @change="handleChange"
                :before-upload="beforeUpload"
                name="file"
              >
                <div>
                  <a-icon type="plus" />
                  <div class="ant-upload-text"></div>
                </div>
              </a-upload>
              <a-modal
                :visible="previewVisible"
                :footer="null"
                @cancel="handleCancel"
              >
                <img alt="example" style="width: 100%" :src="previewImage" />
              </a-modal>
            </a-col>
            <a-col :span="24" :md="24">
              <a-textarea
                placeholder="产品详细介绍"
                :rows="4"
                v-model="form.description"
              />
            </a-col>
          </a-row>
          <a-row gutter="12">
            <a-divider orientation="left" id="file-information">
              文件上传
            </a-divider>
            <a-col :span="24" :md="24">
              <a-upload
                action="/api/materials_files/"
                :headers="{
                  'X-CSRFToken': Cookies.get('csrftoken'),
                  Authorization: `Bearer ${Cookies.get('access')}`,
                }"
                :file-list="form.file_items"
                @preview="handleFilePreview"
                @change="handleFileChange"
                :before-upload="beforeFileUpload"
                name="file"
              >
                <a-button> <a-icon type="upload" /> 上传文件 </a-button>
              </a-upload>
            </a-col>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="beginning-inventory">
              期初库存
            </a-divider>
            <a-col :span="24" :md="24">
              <a-table
                rowKey="id"
                :columns="columns"
                :data-source="warehouseItems"
                :loading="loading"
                :pagination="false"
                style="width: 100%"
              >
                <div slot="initial_quantity" slot-scope="value, item">
                  <div v-if="!!form.enable_batch_control">
                    <a-input
                      :value="item.initial_quantity"
                      disabled
                      style="width: 75%"
                    />
                  </div>
                  <a-input-number
                    v-else
                    :value="item.initial_quantity"
                    min="0"
                    @change="(value) => changeQuantity(value, item)"
                  />
                </div>
              </a-table>
            </a-col>
          </a-row>

          <a-row gutter="12">
            <a-divider orientation="left" id="inventory-warning">
              库存预警
            </a-divider>
            <a-col :span="24" :md="12">
              <a-form-model-item
                prop="enable_inventory_warning"
                label="启用库存警告"
              >
                <a-switch
                  checked-children="启用"
                  un-checked-children="禁用"
                  v-model="form.enable_inventory_warning"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_upper" label="库存上限">
                <a-input-number
                  v-model="form.inventory_upper"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="24" :md="12" v-if="!!form.enable_inventory_warning">
              <a-form-model-item prop="inventory_lower" label="库存下限">
                <a-input-number
                  v-model="form.inventory_lower"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>
      </div>
    </a-modal>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import {
  materialsInformationCreate,
  materialsInformationUpdate,
  materialsAttrList,
} from "@/api/materials";
import { materialsClassificationSpecList } from "@/api/materials";
import { supplierCreate } from "@/api/basicData";
export default {
  name: "FormModal",
  props: [
    "visible",
    "form",
    "warehouseItems",
    "classificationItems",
    "unitItems",
    "supplierItems",
  ],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      Cookies,
      loading: false,
      columns: [
        {
          title: "仓库",
          dataIndex: "name",
          key: "name",
        },
        {
          title: "初始库存",
          dataIndex: "initial_quantity",
          key: "initial_quantity",
          scopedSlots: { customRender: "initial_quantity" },
        },
      ],
      isKeepAdd: false,
      categoryForm: {},
      categoryVisible: false,
      rules: {
        name: [
          { required: true, message: "请输入产品名称", trigger: "change" },
        ],
        number: [
          { required: true, message: "请输入产品编号", trigger: "change" },
        ],
        purchase_link: [
          { max: 255, message: "链接限制在255字符", trigger: "change" },
        ],
      },
      previewVisible: false,
      previewImage: "",
      curWarehouse: {},

      attributes: {}, // 所有类目的属性
      select_attributes: {},
      SpecList: [], // 当前类目的规格列表
    };
  },

  computed: {
    activeAttributes() {
      if (Array.isArray(this.attributes)) {
        // 正确的使用 filter 方法
        const activeAttributes = this.attributes.filter(
          (item) => item.category === this.form.category
        );
        activeAttributes.forEach((attr) => {
          const matchingSpec = this.SpecList.find(
            (spec) => spec.attribute_id === attr.id
          );
          if (matchingSpec) {
            // 添加 values 字段并赋值
            attr.values = matchingSpec.value_list;
          } else {
            // 如果没有匹配的 SpecList 项，则确保 values 是空数组
            attr.values = [];
          }
        });

        return activeAttributes;
      } else {
        // 如果 attributes 不存在，返回空数组
        return [];
      }
    },

    process_length(length_list) {
      return length_list;
    },

    suggest_name() {
      let dimensions = []; // 用于存储尺寸信息（包括单位）
      let require_name = []; // 用于存储其他信息
      let commonUnit = null; // 用于确定是否有一个通用的单位
      require_name.push(this.form.category_name);
      // 遍历选中的属性项
      for (const key in this.select_attributes) {
        const attr_value = this.select_attributes[key];
        const item = this.activeAttributes.find(
          (item) => item.id === parseInt(key)
        );

        // 如果需要组合名称并且名称包含 "长", "宽", "高"
        if (item && item.name_composition) {
          if (["长", "宽", "高"].includes(item.name)) {
            // 收集数值和单位
            if (commonUnit === null) {
              commonUnit = item.unit_name; // 设置初始单位
            }
            if (commonUnit && item.unit_name !== commonUnit) {
              commonUnit = false; // 如果单位不一致，设置为 false
            }
            dimensions.push(attr_value + (item.unit_name || ""));
          } else {
            // 收集其他需要合并的信息
            if (item.unit_name && item.unit_name !== "个") {
              // require_name.push(item.name + attr_value + item.unit_name);
              require_name.push(attr_value + item.unit_name);
            } else {
              // require_name.push(item.name + attr_value);
              require_name.push(attr_value);
            }
          }
        }
      }

      // 检查是否有统一的单位
      if (commonUnit) {
        dimensions = dimensions.map((dim) => dim.replace(commonUnit, "")); // 移除单位
        require_name.push(dimensions.join("*") + commonUnit); // 在最后加上统一的单位
      } else {
        require_name.push(dimensions.join("*")); // 直接连接，各自带有单位
      }

      // 返回结果字符串
      // console.log('require_name',require_name)
      require_name = require_name.filter((item) => item !== "");
      return require_name.join("_");
    },
  },

  methods: {
    init_data() {},
    changeQuantity(value, item) {
      item["initial_quantity"] = value || 0;
      this.warehouseItems = this.$functions.replaceItem(
        this.warehouseItems,
        item
      );
    },

    fill_select_attr() {
      if (Array.isArray(this.form.attributes)) {
        this.select_attributes = this.form.attributes.reduce((obj, item) => {
          obj[item.attribute] = item.value;
          return obj;
        }, {});
      }
      this.fill_category_spec();
    },
    fill_category_spec() {
      materialsClassificationSpecList({ category_id: this.form.category }).then(
        (resp) => {
          console.log("resp", resp);
          this.SpecList = resp.result;
        }
      );
    },

    onCategoryChange(value) {
      console.log("3333", value);
      // this.form.attributes = {};  // 清空旧的属性值
      // this.activeAttributes = this.attributes[value] || [];
      // this.activeAttributes = this.attributes.filter(item => item.category === value);
      // console.log(value)
      if (value == this.form.category) {
        this.fill_select_attr();
      } else {
        this.select_attributes = {};
      }
    },

    fetchCategoriesAttr() {
      // 伪代码，你需要从后端获取类目和属性数据
      materialsAttrList({ page_size: 999999 }).then((data) => {
        this.attributes = data.results;
        // console.log('this.attributes',data)
      });
    },

    async handlePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await this.$functions.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },
    handleChange({ fileList }) {
      this.$set(this.form, "image_items", fileList);
    },
    beforeUpload() {},
    handleCancel() {
      this.previewVisible = false;
    },

    // 文件相关的
    handleFilePreview(file) {
      window.open(file.url);
    },

    handleFileChange({ fileList }) {
      this.$set(this.form, "file_items", fileList);
    },

    beforeFileUpload(file) {
      // 在这里可以添加文件上传前的检查逻辑
      // const isAllowedType = ['application/pdf', 'image/jpeg', 'image/png'].includes(file.type);
      // if (!isAllowedType) {
      //   this.$message.error('只能上传 PDF 或图片文件!');
      //   return false;
      // }
      // return true;  // 返回 false 则停止上传
    },

    change_select_attributes() {
      this.form.attributes = Object.entries(this.select_attributes)
        .filter(([key, value]) => value) //先过滤
        .map(([key, value]) => ({
          attribute: key, // 将键转换为整数
          value: value,
          // material:this.form.id
          material: this.form.id,
        }));
    },
    confirm() {
      this.change_select_attributes();
      let file_ids = this.form.file_items
        .filter((item) => item.status === "done")
        .map((item) => item.response.id); // 假设后端返回文件 ID 在 response.id 字段

      let image_items = this.form.image_items.map((item) => {
        return item.response.id;
      });
      let inventory_items = this.warehouseItems.map((item) => {
        return {
          warehouse: item.id,
          initial_quantity: item.initial_quantity,
        };
      });
      let formatData = {
        ...this.form,
        ...{
          materials_images: image_items,
          materials_files: file_ids, // 添加文件 ID 列表
          inventory_items,
        },
        // ...{
        //   attributes:this.select_attributes,
        // }
      };
      console.log(formatData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          let func = this.form.id
            ? materialsInformationUpdate
            : materialsInformationCreate;
          func(formatData)
            .then((data) => {
              this.$message.success(this.form.id ? "修改成功" : "新增成功");
              this.$emit(this.form.id ? "update" : "create", data);
              this.cancel();
            })
            .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },
    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },

    //供应商部分

    handleSearch(value) {
      this.searchQuery = value; // 更新搜索词
    },
    renderNotFoundContent() {
      return (
        <div>
          找不到结果，
          <a-button type="link" onClick={this.handleAddSupplier}>
            添加新供应商
          </a-button>
        </div>
      );
    },
    handleAddSupplier() {
      console.log("用户输入的供应商名称是：", this.searchQuery);
      // 在这里你可以打开一个模态对话框或导航到一个表单页面
      // 你可以将 searchQuery 作为预填充数据传递
      // 例如：
      this.openAddSupplierDialog(this.searchQuery);
    },
    openAddSupplierDialog(supplierName) {
      // 假设这是打开对话框的方法
      // 在对话框的表单中预填充供应商名称
      console.log(`跳转到添加供应商的表单页面或打开对话框${supplierName}`);
      const supply_form = {
        is_active: true,
        name: supplierName,
      };

      this.loading = true;
      supplierCreate(supply_form)
        .then((data) => {
          this.form.supplier = data.id;
          this.supplierItems.push(data);
          this.$message.success("新增成功");
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  watch: {},

  mounted() {
    this.fetchCategoriesAttr();
  },
};
</script>

<style scoped></style>