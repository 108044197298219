export default [
  {
    key: '1', name: '报表统计', icon: 'line-chart', submenus: [
      { key: '/report/production_report', name: '生产报表' },
      { key: '/report/sale_report', name: '销售报表' },
      { key: '/report/purchase_report', name: '采购报表' },
      { key: '/report/stock_report', name: '产品库存报表' },
      { key: '/report/stockm_report', name: '物料库存报表' },
      { key: '/report/income_expense_statistics', name: '收支统计' },
      { key: '/report/batch_report', name: '批次报表' },
    ]
  },
  {
    key: '2', name: '基础数据', icon: 'table', submenus: [
      { key: '/basicData/client', name: '客户管理'},
      { key: '/basicData/supplier', name: '供应商管理'},
      { key: '/basicData/warehousem', name: '物料仓库管理'},
      { key: '/basicData/warehouse', name: '产品仓库管理'},
      { key: '/basicData/settlement_account', name: '结算账户'},
      { key: '/basicData/revenue_expenditure_items', name: '收支项目'},
    ]
  },
  {
    key: '3', name: '生产管理', icon: 'database', submenus: [
      // { key: '/production/production_create', name: '生产开单' },
      { key: '/production/production_record', name: '生产记录' },
      { key: '/production/process', name: '生产流程' },
    ]
  },
  {
    key: '4', name: '物料管理', icon: 'appstore', submenus: [
      { key: '/materials/classification', name: '物料分类' },
      { key: '/materials/attr', name: '物料属性' },
      { key: '/materials/unit', name: '物料单位' },
      { key: '/materials/information', name: '物料信息' },
      { key: '/materials/production', name: '物料生产' },
      { key: '/materials/development', name: '物料开发' },
      // { key: '/goods/temporary_warning', name: '临期预警' },
    ]
  },
  {
    key: '5', name: '产品管理', icon: 'appstore', submenus: [
      { key: '/goods/classification', name: '产品分类' },
      { key: '/goods/unit', name: '产品单位' },
      { key: '/goods/information', name: '产品信息' },
      // { key: '/goods/temporary_warning', name: '临期预警' },
      // { key: '/goods/dev_create', name: '产品开发' },
      { key: '/goods/dev_record', name: '开发记录' },
      // { key: '/goods/dev_record_detail', name: '开发详情' },
    ]
  },
  {
    key: '6', name: '采购管理', icon: 'shopping-cart', submenus: [
      { key: '/purchasing/purchase_create', name: '采购开单' },
      { key: '/purchasing/purchase_materials', name: '生产采购' },
      { key: '/purchasing/purchase_record', name: '采购记录' },
      { key: '/purchasing/purchase_return_create', name: '采购退货' },
      { key: '/purchasing/return_record', name: '退货记录' },
    ]
  },
  {
    key: '7', name: '销售管理', icon: 'shopping', submenus: [
      { key: '/sale/sale_create', name: '销售开单' },
      { key: '/sale/sale_record', name: '销售记录' },
      { key: '/sale/sale_return_create', name: '销售退货' },
      { key: '/sale/sale_return_record', name: '退货记录' },
    ]
  },
  {
    key: '8', name: '物料库存管理', icon: 'database', submenus: [
      { key: '/warehousem/inStock', name: '入库任务' },
      // { key: '/warehousem/outStock', name: '出库任务' },
      { key: '/warehousem/inventory', name: '盘点' },
      { key: '/warehousem/flow', name: '库存流水' },
    ]
  },
  {
    key: '9', name: '产品库存管理', icon: 'database', submenus: [
      { key: '/warehouse/inStock', name: '入库任务' },
      { key: '/warehouse/outStock', name: '出库任务' },
      { key: '/warehouse/inventory', name: '盘点' },
      { key: '/warehouse/allocation', name: '调拨' },
      { key: '/warehouse/flow', name: '库存流水' },
    ]
  },
  {
    key: '10', name: '财务管理', icon: 'dollar', submenus: [
      { key: '/finance/financial_overview', name: '财务总览' },
      { key: '/finance/arrears_payable', name: '应付账款' },
      // { key: '/finance/payment', name: '付款' },
      // { key: '/finance/arrears_receivable', name: '应收欠款' },
      // { key: '/finance/collection', name: '收款' },
      
      { key: '/finance/arrears_order', name: '生产账款' },

      { key: '/finance/account_transfer', name: '账户转账' },
      { key: '/finance/income_and_pay', name: '日常收支' },
      { key: '/finance/flow', name: '资金流水' },
    ]
  },
  {
    key: '11', name: '人力管理', icon: 'account-book', submenus: [
      { key: '/hrms/employee', name: '员工管理' },
      { key: '/hrms/work_records', name: '考勤记录' },
      { key: '/hrms/salary_records', name: '临时工薪资结算' },
      { key: '/hrms/tsalary_records', name: '正式工薪资结算' },
    ]
  },
  {
    key: '12', name: '系统管理', icon: 'team', submenus: [
      { key: '/role', name: '角色管理' },
      { key: '/account', name: '员工账号' },
      { key: '/config', name: '系统配置' },
    ]
  },
]