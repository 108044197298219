<template>
  <div>
    <a-card title="生产详情">
      <a-button
        slot="extra"
        type="primary"
        style="margin-right: 8px"
        ghost
       @click="handlePrint"
      >
        <a-icon type="printer" />打印</a-button>
      <a-button
        slot="extra"
        type="primary"
        ghost
        @click="
          () => {
            this.$router.go(-1);
          }
        "
      >
        <a-icon type="left" />返回</a-button
      >

      <section id="printContent" class="print-content">
        <a-spin :spinning="loading">
          <a-form-model
            ref="order_form"
            :model="order_form"
            :rules="rules"
            :label-col="{ span: 8 }"
            :wrapper-col="{ span: 16 }"
          >
            <a-row>
              <a-col :span="6" style="width: 320px">
                <a-form-model-item
                  prop="number"
                  label="生产编号"
                  class="bold-label"
                >
                  <a-input :disabled="true" v-model="order_form.number" />
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="purchase_quantity" label="生产数量">
                  <a-input
                    :disabled="true"
                    v-model="order_form.purchase_quantity"
                  />
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="creator_name" label="下单人">
                  <a-input :disabled="true" v-model="order_form.creator_name" />
                </a-form-model-item>
              </a-col>

              <a-col :span="6" style="width: 320px">
                <a-form-model-item prop="create_time" label="下单时间">
                  <a-input :disabled="true" v-model="order_form.create_time" />
                </a-form-model-item>
              </a-col>
            </a-row>
          </a-form-model>
          <a-divider orientation="left" style="margin-top: 30px"
            >物料信息</a-divider
          >
          <div>
            <div style="margin-top: 10px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="material_columns"
                :data-source="this.materialItem"
                :pagination="false"
              >
              </a-table>
            </div>
          </div>

          <div style="margin-top: 10px">
            <div style="margin-top: 16px">
              <a-table
                rowKey="id"
                size="middle"
                :columns="materials_columns"
                :data-source="materialsData"
                :pagination="false"
              >
              </a-table>
            </div>
          </div>
        </a-spin>
      </section>

      <a-divider orientation="left">状态信息</a-divider>
      <div>
        <a-row gutter="16">
          <a-col :span="4">
            <a-form-model-item
              label="生产状态"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-select
                v-model="order_form.status"
                style="width: 100%"
                @change="checkStatusChange"
              >
                <a-select-option
                  v-for="status in statusList"
                  :key="status"
                  :value="status"
                >
                  {{ statusMap[status] }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="4">
            <a-form-model-item
              label="备注"
              :label-col="{ span: 24 }"
              :wrapper-col="{ span: 24 }"
            >
              <a-input v-model="order_form.remark" style="width: 100%" />
            </a-form-model-item>
          </a-col>
        </a-row>
      </div>

      <div style="margin-top: 32px">
        <a-popconfirm title="确保保存吗?" @confirm="update">
          <a-button type="primary" :loading="loading">提交</a-button>
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
// import { getDevelopSearchByMaterial } from "@/api/materials";
import { materialsDevelopList } from "@/api/materials";

import { purchaseOrderUpdate, purchaseOrderDetail } from "@/api/purchasing";
import NP from "number-precision";

export default {
  data() {
    return {
      loading: false,

      statusMap: {
        Analysis: "待生产",
        Purchase: "物料采购",
        InProduction: "生产中",
        Complete: "生产完成",
        // Shipped: "已发货",
        Cancel: "作废",
      },

      material_columns: [
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
      ],

      materials_columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
          width: 150,
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
          width: 150,
        },
        {
          title: "所需数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
          width: 120,
          // scopedSlots: { customRender: "purchase_quantity" },
        },
        {
          title: "单位量",
          dataIndex: "material_son_unit_quantity",
          key: "material_son_unit_quantity",
          width: 120,
        },
        {
          title: "单位",
          dataIndex: "unit_name",
          key: "unit_name",
          width: 80,
        },
        {
          title: "采购单价(元)",
          dataIndex: "purchase_price",
          key: "purchase_price",
          width: 120,
        },
        {
          title: "仓库库存",
          dataIndex: "materials_inventory",
          key: "materials_inventory",
          width: 80,
        },

        {
          title: "金额",
          dataIndex: "OneAmount",
          key: "OneAmount",
          width: 80,
          customRender: (value, item) => {
            if (item.isTotal) return value;
            value = NP.times(
              item.purchase_quantity,
              item.material_son_unit_quantity,
              item.purchase_price
            );
            // return item.id ? NP.round(value, 2) : "";
            return NP.round(value, 2);
          },
        },
        {
          title: "准备状态",
          dataIndex: "pr_status",
          key: "pr_status",
          width: 120,
          customRender: (value, item) => {
            if (item.material_son > 0) {
              value = NP.times(
                item.purchase_quantity,
                this.purchase_materials_item.purchase_quantity
              );
              let need_inventory = NP.minus(item.materials_inventory, value);
              if (NP.round(need_inventory, 2) > 0) {
                return "库存充足";
              } else {
                return "库存不足";
              }
            }
          },
        },
        {
          title: "操作",
          dataIndex: "action",
          key: "action",
          width: 160,
          scopedSlots: { customRender: "action" },
        },
      ],
      materialItems: [],
      materialItem: [],
      order_form: {
        status: "",
      },
      currentStatus: "",
      status_: "",
      pending_status: ["Analysis"],
      production_status: ["InProduction"],
      validTransitions: {
        Analysis: ["Analysis", "Purchase", "InProduction", "Cancel"],
        Purchase: ["Purchase", "InProduction", "Cancel"],
        InProduction: ["InProduction", "Complete", "Cancel"],
        // Complete: ["Complete", "Shipped"],
        Complete:this.get_complete_status(),
        Cancel: ["Analysis", "InProduction", "Cancel"],
      },
    };
  },

  created() {
    const order_id = this.$route.query.id;
    this.getOrderDetail(order_id);
  },

  computed: {
    materialsData() {
      let totalQuantity = 0,
        one_amount = 0;
      for (let item of this.materialItems) {
        totalQuantity = NP.plus(totalQuantity, item.purchase_quantity);
        let amount_cost = NP.times(item.purchase_quantity, item.purchase_price,item.material_son_unit_quantity);
        one_amount = NP.plus(one_amount, amount_cost);
      }
      return [
        ...this.materialItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          purchase_quantity: totalQuantity,
          OneAmount: one_amount,
        },
      ];
    },
    statusList() {
      return this.validTransitions[this.currentStatus];
    },
    calculatedInventory() {
      return this.materialItems.map((item) => {
        if (item.material_son > 0) {
          let value = NP.times(
            item.purchase_quantity,
            this.order_form.purchase_quantity,
            item.material_son_unit_quantity
          );
          value = NP.minus(item.materials_inventory, value);
          return NP.round(value, 2);
        }
        return "";
      });
    },
  },
  methods: {
    init_data(data) {
      console.log("res", data);
      const results = data.results;
      if (results.length == 1) {
        const res = results[0];
        this.form = {
          id: res.id,
          number: res.number,
          status: res.status,
          remark: res.remark,
        };

        // 初始化物料数据
        this.materialItem = [
          {
            id: res.id,
            materials: res.material,
            materials_name: res.materials_name,
            materials_number: res.materials_number,
          },
        ];

        // 优化物料列表数据处理
        this.materialItems = res.materials_dev_items.map((item) => {
          const inventory_item = item.materials_inventory?.[0] || {}; // 使用可选链操作符

          return {
            id: item.id,
            material_son: item.material_son,
            material_son_unit_quantity: item.material_son_unit_quantity,
            purchase_quantity: item.material_son_quantity || 0, // 如果没有数量，默认0
            materials_number: inventory_item.materials_number || "", // 默认空字符串
            materials_name: inventory_item.materials_name || "",
            materials_spec: inventory_item.materials_spec || "",
            unit_name: inventory_item.unit_name || "",
            purchase_price: inventory_item.purchase_price || 0, // 默认0
            materials_inventory: inventory_item.total_quantity || 0, // 默认0
          };
        });
      } else if (results.length == 0) {
        this.$message.error("Development with this material not found");
      } else {
        this.$message.error("匹配到多个开发单，请检查数据");
      }
    },

    getDevDetail() {
      // 网络请求获取数据
      materialsDevelopList({ material: this.purchase_materials_item.materials })
        .then((res) => {
          // 初始化表单数据
          this.init_data(res);
        })
        .catch((error) => {
          // 错误处理，确保用户能看到错误信息
          console.error("Failed to fetch data:", error);
          this.$message.error("加载数据失败，请稍后重试。");
        });
    },

    async getOrderDetail(order_id) {
      this.loading = true;
      try {
        const response = await purchaseOrderDetail({ id: order_id });
        console.log("response", response);
        this.purchase_materials_item = response.purchase_materials_items[0];
        this.order_form = {
          id: response.id,
          number: response.number,
          creator_name: response.creator_name,
          create_time: response.create_time,
          purchase_quantity: response.total_quantity,
          supplier: response.supplier,
          status: response.status,
          remark: response.remark,
        };

        if (
          (this.order_form.status === undefined) |
          (this.order_form.status === null)
        ) {
          this.order_form.status = "Analysis";
        }

        // this.$set(this.order_form, "purchase_quantity", 1000); // 使用 Vue.set 更新
        this.currentStatus = this.order_form.status;

        await this.getDevDetail();
      } catch (error) {
        console.error("Error fetching order details:", error);
      } finally {
        this.loading = false;
      }
    },
    get_complete_status(){
      if(this.$store.state.user.username == "jason"){
        return ["Complete","Cancel"]
      }
      return ["Complete"]
    },

    checkStatusChange(newStatus) {
      var hasNegative = this.calculatedInventory.some((element) => element < 0);
      if (
        this.pending_status.includes(this.currentStatus) &&
        this.production_status.includes(newStatus) &&
        hasNegative
      ) {
        this.$message.error(`物料库存不足,无法更新为${newStatus}状态`);
        this.order_form.status = this.currentStatus;
        return false;
      }
    },

    handlePrint() {
      this.$nextTick(() => {
        window.print();
      });
    },

    update() {
      this.loading = true;
      purchaseOrderUpdate(this.order_form)
        .then((data) => {
          // this.init_data(data);
          this.currentStatus = data.status;
          this.$message.success("修改成功");
        })
        .catch((err) => {
          // 判断错误是否有响应对象
          console.log(err);
          if (err.response) {
            // 处理包含错误的响应数据
            const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
            this.$message.error(errorMessage);
            this.order_form.status = this.currentStatus;
          } else {
            // 处理其他类型的错误（例如网络错误）
            this.$message.error("请求错误");
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
};
</script>

<style>


/* 全局样式文件或组件的<style>部分 */
@media print {

  body * {
    visibility: hidden;
  }

  /* 只显示需要打印的部分，确保它是可见的 */
  .print-content * {
    visibility: visible;
  }

  .print-content {
    position: absolute;
    left: 0;
    top: 0;
  }
}
 

</style>