<template>
  <div>
    <a-table
      :columns="columns"
      :data-source="items"
      :loading="loading"
      :pagination="false"
      :scroll="{ y: tableHeight }"
    >
      <template slot="action" slot-scope="value, item">
        <a-button-group>
          <a-button type="link" @click="viewTask(item)">编辑</a-button>
        </a-button-group>
      </template>
    </a-table>
  </div>
</template>

<script>
export default {
  name: "MaterialTaskTable",
  props: {
    items: Array,
    loading: Boolean,
  },
  data() {
    return {
      tableHeight: window.innerHeight,
      statusList: [
        {
          id: 1,
          code: "Analysis",
          name: "待审生产",
        },
        //  {
        //   id: 2,
        //   code: 'SamplePurchase',
        //   name: '样品采购',
        // },
         {
          id: 3,
          code: 'Purchase',
          name: '物料采购',
        },
        {
          id: 4,
          code: "InProduction",
          name: "生产中",
        },
        {
          id: 5,
          code: "Complete",
          name: "生产完成",
        },
        // {
        //   id: 7,
        //   code: 'Shipped',
        //   name: '已发货'
        // },
        {
          id: 6,
          code: "Cancel",
          name: "作废",
        },
      ],
      columns: [
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "下单用户",
          dataIndex: "creator_name",
          key: "creator_name",
        },
        {
          title: "状态",
          dataIndex: "status",
          key: "status",
          customRender: (text) => {
            const statusItem = this.statusList.find(
              (status) => status.code === text
            );
            return statusItem ? statusItem.name : "待生产";
          },
        },
        {
          title: "物料编号",
          dataIndex: "materials_number",
          key: "materials_number",
        },
        {
          title: "物料名称",
          dataIndex: "materials_name",
          key: "materials_name",
        },
        {
          title: "数量",
          dataIndex: "purchase_quantity",
          key: "purchase_quantity",
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
    };
  },
  methods: {
    statusName(code) {
      const statusMap = {
        Analysis: "待审生产",
        Purchase: "物料采购",
        InProduction: "生产中",
        Complete: "生产完成",
        Cancel: "作废",
      };
      console.log("code", code);
      return statusMap[code] || "未知状态";
    },
    viewTask(item) {
      // 替换为你的路由逻辑或其他适当的动作
      this.$router.push({
        path: "/materials/production_record_detail",
        query: { id: item.id },
      });
    },
  },
};
</script>

<style scoped>
</style>
