<template>
  <div>
    <a-card style="height: calc(80vh - 48px);">
      <div slot="title" style="height: 24px;">
        <span>物料入库任务提醒</span>
      </div>
      
      <a-table :columns="columns" :data-source="items" size="small" :pagination="false" :loading="loading"
        :scroll="{y: tableHeight}">
        
        <template slot="details" slot-scope="text, record">
          <a-tooltip :title="generateDetails(record)">
            <span
              style="display: inline-block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ generateDetails(record).slice(0, 30) }}
            </span>
          </a-tooltip>
        </template>

        <template slot="supplier_name" slot-scope="text, record">
          <a-tooltip :title="text">
            <span
              style="display: inline-block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ text.slice(0, 20) }}
            </span>
          </a-tooltip>
        </template>
        
        <template slot="tracking_number" slot-scope="text, record, index">
          <a-input
            v-model="record.tracking_number"
            @blur="handleSave(index, record)"
            placeholder="请输入追踪号"
          />
        </template>
        
        <div slot="action" slot-scope="value, item">
          <a-button type="link" @click="viewTask(item)">入库</a-button>
        </div>
      </a-table>
      
      <div style="text-align: center; margin-top: 16px;">
        <a-pagination v-model="searchForm.page" :total="totalRows" :pageSize="perPage" show-less-items
          @change="changePage" />
      </div>
    </a-card>
  </div>
</template>

<script>
  import { stockInList } from '@/api/system'
  import { stockInUpdate } from '@/api/warehousem'
  import moment from 'moment'

  export default {
    name: 'WarehouseTask',
    data() {
      return {
        moment,
        taskType: 'purchase',
        items: [],
        perPage: 16,
        totalRows: 0,
        searchForm: { page: 1, page_size: 10, is_done: false },
        loading: false,
        columns: [
        {
          title: "详情",
          dataIndex: "details",
          width: '200px',
          scopedSlots: { customRender: 'details' },
        },
        {
          title: "供应商",
          dataIndex: "supplier_name",
          width: '150px',
          scopedSlots: { customRender: 'supplier_name' },
        },
        {
          title: "期望发货",
          dataIndex: "eta",
          customRender: (value, record) => {
            const today = new Date().toISOString().split('T')[0];
            const isToday = value === today;
            const isPast = value < today;
            const className = record.tracking_number ? '' : (isToday ? 'is-today' : isPast ? 'is-past' : '');
            // const className = isToday ? 'is-today' : isPast ? 'is-past' : '';
            return {
              children: <div class={className}>{value}</div>,
              attrs: {},
            };
          },
        },
        {
          title: "追踪号",
          dataIndex: "tracking_number",
          scopedSlots: { customRender: "tracking_number" },
          width: 170,
        }, 
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
        },
        ],
        tableHeight: window.innerHeight / 2 - 50,
      };
    },
    methods: {
      initialize() {
        this.list();
      },
      list() {
        this.loading = true;
        stockInList(this.searchForm).then(resp => {
          this.totalRows = resp.count;
          this.items = resp.results;
          this.items = resp.results.map(item => {
            return { ...item, original_tracking_number: item.tracking_number };
          });
        }).finally(() => {
          this.loading = false;
        });
      },
      viewTask(item) {
          this.$router.push({ path: '/warehousem/inStock_create', query: { id: item.id } });
      },
      changeTask() {
        this.searchForm.page = 1;
        this.list();
      },
      changePage(value) {
        this.searchForm.page = value;
        this.list();
      },
      generateDetails(item) {
        const results = item.stock_in_goods_items.map(innerItem => {
          const quantityUnit = [
            innerItem['stock_in_quantity'],
            innerItem['unit_name']
          ].filter(value => value != null).join('');  // 直接连接，不使用下划线

          const parts = [
            innerItem['goods_number'],
            innerItem['goods_name'],
            quantityUnit  // 把合并后的数量和单位作为一个部分
          ].filter(value => value != null);  // 继续过滤掉null和undefined

          return parts.join('_');  // 使用下划线连接字符串
        });
        return results.join(', ');
      },
      handleSave(index, record) {
        // 这里可以添加代码，发送修改后的数据到后台进行保存
        if (record.tracking_number === record.original_tracking_number) {
          // 如果追踪号未变化，则不更新
          console.log("追踪号没有变化，无需更新");
          return;
        }
        this.loading = true;
        stockInUpdate({ id: record.id, tracking_number: record.tracking_number }).then(response => {
            this.$message.success('追踪号更新成功');
            // 更新原始追踪号
            record.original_tracking_number = record.tracking_number;
        }).catch(error => {
          this.$message.error('追踪号更新失败');
        }).finally(() => {
          this.loading = false;
        })
        console.log(`保存追踪号: ${record.tracking_number}`);
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
.is-today {
  background-color: yellow;
}

.is-past {
  background-color: red;
}
</style>
