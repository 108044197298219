<template>
  <div>
    <a-card title="薪资结算">
      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button @click="viewPaymentOrders(item)">查看结算单</a-button>
              <a-button type="primary" @click="createPaymentOrder(item)"
                >创建结算单</a-button
              >
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import { salaryUnPaid } from "@/api/hrms";

export default {
  name: "SalaryRecord",
  components: {},
  data() {
    return {
      currentTab:'temporary',
      searchForm: { page: 1, employee_type: 'temporary', page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      visible: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {

    columns() {
      if(this.currentTab === 'permanent'){
        return [{
          title: "序号",
          dataIndex: "index",
          key: "index",
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "编号",
          dataIndex: "number",
          fixed: "left",
        },
        {
          title: "员工姓名",
          dataIndex: "name",
        },
        {
          title: "未结算天数(加班)",
          dataIndex: "count_orders_attendance",
        },
        {
          title: "未结算金额(加班)",
          dataIndex: "total_order_amount_attendance",
        },
        {
          title: "未结算天数(加班)",
          dataIndex: "count_orders_attendance",
        },
        {
          title: "未结算金额(加班)",
          dataIndex: "total_order_amount_attendance",
        },
        {
          title: "操作",
          dataIndex: "action",
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ]
    }else {
      return [{
          title: "序号",
          dataIndex: "index",
          key: "index",
          fixed: "left",
          customRender: (value, item, index) => {
            return index + 1;
          },
          width: 45,
        },
        {
          title: "编号",
          dataIndex: "number",
          fixed: "left",
        },
        {
          title: "员工姓名",
          dataIndex: "name",
        },
        {
          title: "未结算天数",
          dataIndex: "count_orders_attendance",
        },
        {
          title: "未结算金额",
          dataIndex: "total_order_amount_attendance",
        },
        {
          title: "操作",
          dataIndex: "action",
          // fixed: 'right',
          width: 100,
          scopedSlots: { customRender: "action" },
        },
      ]
      }
    }
  },
  methods: {
    initialize() {
      this.list();
    },
    list() {
      this.loading = true;
      salaryUnPaid(this.searchForm)
        .then((data) => {
          // this.pagination.total = data.count;
          this.items = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },
   
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    viewPaymentOrders(employee) {
      // 跳转到查看付款单页面，可以传递客户ID
      this.$router.push({
        path: "/hrms/payment_salary_view",
        query: { employeeId: employee.id },
      });
    },
    createPaymentOrder(employee) {
      // 跳转到创建收款单页面，可以传递客户ID
      this.$router.push({
        path: "/hrms/payment_salary_create",
        query: { employeeId: employee.id },
      });
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>