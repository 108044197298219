<template>
  <div>
    <a-card title="采购记录">
      <a-row>
        <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-input-search v-model="searchForm.search" placeholder="单号,供应商编号/名称" allowClear @search="search" />
        </a-col>
        <!-- <a-col :span="24" :md="8" :xl="6" style="max-width: 256px; margin-bottom: 12px;">
          <a-range-picker @change="onChangePicker" />
        </a-col> -->
      </a-row>

      <a-row style="margin-top: 12px;">
        <a-table size="small" :columns="columns" :dataSource="items" rowKey="id" :loading="loading" :pagination="pagination"
          @change="tableChange">

          <template slot="details" slot-scope="text, record">
          <a-tooltip :title="generateDetails(record)">
            <span
              style="display: inline-block; width: 100%; overflow: hidden; white-space: nowrap; text-overflow: ellipsis;">
              {{ generateDetails(record).slice(0, 20) }}
            </span>
          </a-tooltip>
        </template>

          <div slot="action" slot-scope="value, item">
            <a-button-group size="small">
              <a-button size="small" @click="detial(item)">详情</a-button>
              <a-popconfirm title="确定作废吗?" @confirm="voidItem(item)">
                <a-button type="danger" :disabled="item.is_void">{{ item.is_void ? '已作废' : '作废'}}</a-button>
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
  import { purchaseOrderList, purchaseOrdersVoid } from '@/api/purchasing'

  export default {
    name: 'Warehouse',
    components: {
    },
    data() {
      return {
        columns: [
          {
            title: '序号',
            dataIndex: 'index',
            key: 'index',
            customRender: (value, item, index) => {
              return index + 1
            },
            width: 45
          },
          {
            title: '采购编号',
            dataIndex: 'number',
            sorter: true,
          },
          {
          title: "详情",
          dataIndex: "details",
          width: '200px',
          scopedSlots: { customRender: 'details' },
          },
          {
            title: '供应商',
            dataIndex: 'supplier_name',
          },
          {
            title: '创建人',
            dataIndex: 'creator_name',
          },
          {
            title: '处理日期',
            dataIndex: 'handle_time',
            width: 150
          },
          {
          title: "期望到达时间",
          dataIndex: "eta",
          customRender: (value, record) => {
            const today = new Date().toISOString().split('T')[0];
            const isToday = value === today;
            const isPast = value < today;

            const className = isToday ? 'is-today' : isPast ? 'is-past' : '';
            return {
              children: <div class={className}>{value}</div>,
              attrs: {},
            };
          },
        },
          {
            title: '采购总数量',
            dataIndex: 'total_quantity',
            width: 120
          },
          {
            title: '采购总金额',
            dataIndex: 'total_amount',
            width: 120
          },
          // {
          //   title: '付款金额',
          //   dataIndex: 'payment_amount',
          //   width: 120
          // },
          {
            title: '其他费用',
            dataIndex: 'other_amount',
            width: 120
          },
          {
          title: "备注",
          dataIndex: "remark",
          key: "remark",
        },{
          title: "类型",
          dataIndex: "is_produce",
          key: "is_produce",
          customRender: (text, record) => {
            return text  ? "生产" : "";
          }
        },
          {
            title: '操作',
            dataIndex: 'action',
            scopedSlots: { customRender: 'action' },
            width: 147
          },
        ],
        searchForm: { page: 1, page_size: 16 },
        pagination: { current: 1, total: 0, pageSize: 16 },
        loading: false,
        items: [],
        visible: false,
        targetItem: {},
        form: {},
      };
    },
    computed: {
    },
    methods: {
      initialize() {
        this.list();
      },

      generateDetails(item) {
        console.log(item)
      const results = item.purchase_materials_items.map(innerItem => {
        const quantityUnit = [
          innerItem['purchase_quantity'],
          innerItem['unit_name']
        ].filter(value => value != null).join('');  // 直接连接，不使用下划线

        const parts = [
          innerItem['materials_number'],
          innerItem['materials_name'],
          quantityUnit  // 把合并后的数量和单位作为一个部分
        ].filter(value => value != null);  // 继续过滤掉null和undefined

        return parts.join('_');  // 使用下划线连接字符串
      });
      return results.join(', ');
    },

      list() {
        this.loading = true;
        purchaseOrderList(this.searchForm).then(data => {
          this.pagination.total = data.count;
          this.items = data.results;
        }).finally(() => {
          this.loading = false;
        });
      },
      tableChange(pagination, filters, sorter) {
        this.searchForm.page = pagination.current;
        this.pagination.current = pagination.current;
        this.searchForm.ordering = `${sorter.order == 'descend' ? '-' : ''}${sorter.field}`;
        this.list();
      },
      onChangePicker(date, dateString) {
        let startDate = date[0], endDate = date[1];
        this.searchForm.start_date = startDate ? startDate.format('YYYY-MM-DD') : undefined;
        this.searchForm.end_date = endDate ? endDate.add(1, 'days').format('YYYY-MM-DD') : undefined;
        this.search();
      },
      search() {
        this.searchForm.page = 1;
        this.pagination.current = 1;
        this.list();
      },
      detial(item) {
        this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      },
      voidItem(item) {
        purchaseOrdersVoid({ id: item.id }).then(() => {
          this.$message.success('作废成功');
          this.list();
        });
      },
    },
    mounted() {
      this.initialize();
    },
  }
</script>

<style scoped>
.is-today {
  background-color: yellow;
}

.is-past {
  background-color: red;
}
</style>
