<template>
  <a-select
    placeholder="员工选择"
    v-model="employee"
    style="width: 200px"
  >
    <a-select-option
      v-for="option in employeeItems"
      :key="option.id"
      :value="option"
    >
      {{ option.name }}
    </a-select-option>
  </a-select>
</template>

<script>
export default {
  data() {
    return {
      employee: {},
      employeeItems: [{"id":2,"number":"Y002","name":"曾智利","phone":"13597545552","email":null,"remark":"11","is_active":true,"employee_type":"permanent","salary_month":"6500.00","salary_day":"295.00"},{"id":7,"number":"Y006","name":"曾智祥","phone":"13409991896","email":null,"remark":null,"is_active":true,"employee_type":"permanent","salary_month":"7500.00","salary_day":null},{"id":8,"number":"Y007","name":"郭震","phone":"13277039970","email":null,"remark":null,"is_active":true,"employee_type":"permanent","salary_month":"5100.00","salary_day":null},{"id":9,"number":"Y008","name":"王亮","phone":"17771368659","email":null,"remark":null,"is_active":true,"employee_type":"permanent","salary_month":"5000.00","salary_day":null}], // 假设这是员工列表
    };
  },
};
</script>
