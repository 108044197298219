<template>
  <div>
    <a-modal
      v-model="visible"
      width="750px"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">编辑信息</div>
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4, md: 8 }"
        :wrapper-col="{ span: 20, md: 16 }"
      >
        <a-row gutter="12">
          <a-col :span="24" :md="12">
              <a-form-model-item prop="number" label="编号" >
                <a-input disabled="true" v-model="form.number" />
              </a-form-model-item>
            </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="employee_name" label="员工姓名">
                <a-input :value="form.employee_name"  disabled="true"/>
              </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="work_type" label="类型">
            <a-select
            v-model="form.work_type"
            :disabled="true"
            placeholder="选择类型"
            >
              <a-select-option value="Attendance">
                出勤
              </a-select-option>
              <a-select-option value="Absence">
                缺勤
              </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>
          
          <a-col :span="24" :md="12">
            <a-form-model-item label="日期" required prop="work_date">
              <a-date-picker
                valueFormat="YYYY-MM-DD"
                v-model="form.work_date"
                style="width: 100%;"
                :disabled-date="disabledDate"
              />
            </a-form-model-item>

          </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="salary_day" label="日薪">
              <a-input v-model="form.salary_day" allowClear />
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="form.remark" allowClear />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>


<script>
import { workRecordsUpdate } from "@/api/hrms";
import moment from "moment";
export default {
  name: "FormModalUpdate",
  props: ["visible", "form","currentTab"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      loading: false,
      employeeList: [],
      rules: {
        name: [
          { required: true, message: "请输入产品名称", trigger: "change" },
        ],
      },
    };
  },
  mounted() {
    
  },
  methods: {

    moment,
    disabledDate(current) {
      const day = current.day();
      return current > moment().endOf('day'); // 禁止星期天和星期六
    },

    confirm() {
      
      let formatData = {
        ...this.form,
        
      };
     
      console.log(formatData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          workRecordsUpdate(formatData)
            .then((data) => {
              this.$message.success('修改成功');
              this.$emit("update", data);
              this.cancel();
            })
            .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped>
</style>