<template>
  <a-modal
    title="工作详情"
    v-model="visible"
    width="1300px" 
    @ok="handleOk"
    @cancel="cancel"
  >
    <a-table
      :columns="columns"
      :dataSource="items"
      rowKey="id"
    ></a-table>
  </a-modal>
</template>

<script>
export default {
  props: ['visible','items'],
  model: { prop: 'visible', event: 'cancel' },
  data() {
    return {
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "创建用户",
          dataIndex: "creator_name",
          key: "creator_name",
          // width:'60px'
        },
        {
          title: "员工姓名",
          dataIndex: "employee_name",
          key: "employee_name",
        },
        {
          title: "类型",
          dataIndex: "work_type",
          key: "work_type",
          customRender: (value, item, index) => {
            if (item.isTotal) return "";
            return value !== 'Attendance' ? "缺勤" : "出勤";
          }
        },
        {
          title: "工作日期",
          dataIndex: "work_date",
          key: "work_date",
        },
        {
          title: "日薪",
          dataIndex: "salary_day",
          key: "salary_day",
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
    };
  },
  methods: {
    cancel() {
      console.log('cancel')
        this.$emit('cancel', false);
       
      },
  }
};
</script>
