<template>
  <div>
    <!-- 搜索区域 -->
    <a-card class="search-card">
      <a-row :gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <!-- Month Picker：选择月份后触发 handleMonthChange -->
          <a-month-picker
            v-model="monthValue"
            format="YYYY-MM"
            placeholder="选择月份"
            allowClear
            @change="handleMonthChange"
          />
        </a-col>
        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>
      </a-row>
    </a-card>

    <!-- 表格区域 -->
    <a-card class="table-card">
      <a-row>
        <a-table
          :columns="columns"
          :data-source="tableData"
          :loading="loading"
          rowKey="id"
          :pagination="false"
          bordered
        >
          <!-- 操作列插槽 -->
          <template  slot="action" slot-scope="value, record">
            <a v-if="record.projectName !== '合计'" @click="handleView(record)">
              查看
            </a>
          </template>
        </a-table>
      </a-row>
    </a-card>
  </div>
</template>

<script>
import moment from "moment";
// 这里引入你的接口
import {
  productionSummery,
  ConsumptionSummary,
  DailySummary,
  TemporarySummary,
  EmployeeSummary,
} from "@/api/finance";

export default {
  name: "FinancialOverview",
  data() {
    return {
      // 月份
      monthValue: moment(),
      // 查询参数
      searchForm: {
        start_date: moment().startOf("month").format("YYYY-MM-DD"),
        end_date: moment().endOf("month").format("YYYY-MM-DD"),
      },
      loading: false,
      // 表格配置
      columns: [
        { title: "项目名称", dataIndex: "projectName", key: "projectName" },
        { title: "收入", dataIndex: "income", key: "income" },
        { title: "支出", dataIndex: "expense", key: "expense" },
        { title: "利润", dataIndex: "profit", key: "profit" },
        {
          title: "操作",
          key: "action",
          scopedSlots: { customRender: "action" },
        },
      ],
      tableData: [],
      // 合计
      totalIncome: 0,
      totalExpense: 0,
      totalProfit: 0,
    };
  },
  methods: {
    // 当 month-picker 改变时，更新 searchForm
    handleMonthChange(date) {
      if (!date) {
        this.searchForm.start_date = "";
        this.searchForm.end_date = "";
      } else {
        this.searchForm.start_date = date.startOf("month").format("YYYY-MM-DD");
        this.searchForm.end_date = date.endOf("month").format("YYYY-MM-DD");
      }
    },
    // 点击“查询”
    search() {
      this.list();
    },
    // 核心：请求多个接口并进行合并
    async list() {
      this.loading = true;

      const ConsumptionSearch = {
        ...this.searchForm,
        category: "29",
      };
      const TemporarySummarySearch = {
        ...this.searchForm,
        employee_type: "temporary",
      };
      const PermanentSummarySearch = {
        ...this.searchForm,
        employee_type: "permanent",
      };

      try {
        const [
          ps_response,
          cs_response,
          ds_response,
          ts_response,
          es_response,
        ] = await Promise.all([
          productionSummery(this.searchForm),
          ConsumptionSummary(ConsumptionSearch),
          DailySummary(this.searchForm),
          TemporarySummary(TemporarySummarySearch),
          EmployeeSummary(PermanentSummarySearch),
        ]);

        // 组装表格行数据
        const rows = [
          this.createRow(1, "销售毛利", ps_response),
          this.createRow(2, "消耗品", cs_response),
          this.createRow(3, "日常收支", ds_response),
          this.createRow(4, "临时工收支", ts_response),
          this.createRow(5, "正式工收支", es_response),
        ];

        // 计算合计
        const { sumIncome, sumExpense, sumProfit } = rows.reduce(
          (acc, cur) => {
            acc.sumIncome += parseFloat(cur.income) || 0;
            acc.sumExpense += parseFloat(cur.expense) || 0;
            acc.sumProfit += parseFloat(cur.profit) || 0;
            return acc;
          },
          { sumIncome: 0, sumExpense: 0, sumProfit: 0 }
        );

        // 添加合计行
        rows.push({
          id: 99,
          projectName: "合计",
          income: sumIncome.toFixed(2),
          expense: sumExpense.toFixed(2),
          profit: sumProfit.toFixed(2),
        });

        this.tableData = rows;
        this.totalIncome = sumIncome;
        this.totalExpense = sumExpense;
        this.totalProfit = sumProfit;
      } catch (error) {
        console.error(error);
        this.$message.error("加载数据失败");
      } finally {
        this.loading = false;
      }
    },

    // 统一保留两位小数
    createRow(id, projectName, data) {
      const fix2 = (val) => parseFloat(val || 0).toFixed(2);
      return {
        id,
        projectName,
        income: fix2(data.income),
        expense: fix2(data.expense),
        profit: fix2(data.profit),
      };
    },
    // 点击“查看”
    handleView(record) {
      console.log("查看详情 => ", record);
    },
  },

  mounted() {
    this.list();
  },
};
</script>


<style scoped>
.search-card {
  margin-bottom: 16px;
}
.table-card {
  margin-top: 0px;
}
</style>
