<template>
  <div>
    <a-card title="薪资结算单创建">
      <a-button slot="extra" type="primary" ghost @click="() => { this.$router.go(-1); }"> <a-icon type="left" />返回</a-button>
      <a-spin :spinning="loading">
        <a-form-model
          ref="form"
          :model="form"
          :rules="rules"
          :label-col="{ span: 8 }"
          :wrapper-col="{ span: 16 }"
        >
          <a-row>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="number" label="结算编号">
                <a-input v-model="form.number" />
              </a-form-model-item>
            </a-col>
            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="employee" label="员工">
                <a-input
                  :value="employee.name"
                  disabled="true"
                  style="font-weight: bold"
                />
              </a-form-model-item>
            </a-col>

            <a-col :span="6" style="width: 320px">
              <a-form-model-item prop="remark" label="备注">
                <a-input v-model="form.remark" allowClear />
              </a-form-model-item>
            </a-col>
          </a-row>
        </a-form-model>

        <a-divider orientation="left">未结算考勤</a-divider>
        <a-button type="primary" @click="downloadOrders">下载信息</a-button>
        <div>
          <div style="margin-top: 16px">
            <a-table
              rowKey="id"
              size="middle"
              :columns="columns"
              :loading="tb_loading"
              :data-source="productsData"
              :pagination="false"
              :row-selection="{
                selectedRowKeys: selectedRowKeys,
                onChange: onSelectChange,
              }"
              :scroll="{ y: '400px' }"
            >
            </a-table>
          </div>
        </div>
        <a-divider orientation="left">账单信息</a-divider>
        <div>
          <a-row gutter="16">

            <a-col :span="4">
              <a-form-model-item
                prop="other_amount"
                label="其他费用"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="form.other_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item
                label="总计金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  :value="OrderTotalAmount"
                  :disabled="true"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
            <a-col :span="4">
              <a-form-model-item
                label="实际支付金额(元)"
                :label-col="{ span: 24 }"
                :wrapper-col="{ span: 24 }"
              >
                <a-input-number
                  v-model="collection_amount"
                  style="width: 100%"
                />
              </a-form-model-item>
            </a-col>
          </a-row>
        </div>

        <a-divider orientation="left">上传付款截图</a-divider>
        <div>
          <a-col :span="24" :md="24">
            <a-upload
              action="/api/salary_record_images/"
              list-type="picture-card"
              :headers="{
                'X-CSRFToken': Cookies.get('csrftoken'),
                Authorization: `Bearer ${Cookies.get('access')}`,
              }"
              :file-list="form.image_items"
              @preview="handlePreview"
              @change="handleChange"
              :before-upload="beforeUpload"
              name="image"
            >
              <div>
                <a-icon type="plus" />
                <div class="ant-upload-text"></div>
              </div>
            </a-upload>
            <a-modal
              :visible="previewVisible"
              :footer="null"
              @cancel="handleCancel"
            >
              <img alt="example" style="width: 100%" :src="previewImage" />
            </a-modal>
          </a-col>
        </div>
      </a-spin>

      <div style="margin-top: 32px">
        <a-popconfirm title="确定创建吗?" @confirm="create">
          <a-button type="primary" :loading="loading" :disabled="button_dis"
            >批量付款</a-button
          >
        </a-popconfirm>
      </div>
    </a-card>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import moment from "moment";
import * as XLSX from "xlsx";
import { salaryRecordsCreate, getSalaryRecordsNumber } from "@/api/hrms";
import { unpaidWorkList } from "@/api/hrms";
import { employeeOption } from "@/api/option";
import NP from "number-precision";

export default {
  data() {
    return {
      Cookies,
      description: "新增",
      employee: "",
      selectedRowKeys: [],
      collection_amount: "",
      button_dis: false,
      tb_loading: false,
      loading: false,
      model: {},
      form: {},

      // 图片相关
      fileList: [],
      previewVisible: false,
      previewImage: "",
      headers: {
        "X-CSRFToken": Cookies.get("csrftoken"),
        Authorization: `Bearer ${Cookies.get("access")}`,
      },

      rules: {
        number: [
          { required: true, message: "请输入编号", trigger: "change" },
          { max: 32, message: "超出最大长度 (32)", trigger: "change" },
        ],
        handle_time: [
          { required: true, message: "请选择处理日期", trigger: "change" },
        ],
        other_amount: [
          {
            pattern: new RegExp(/^\d{0,14}(?:\.\d{0,2})?$/),
            message: "其他费用格式不正确",
            trigger: "change",
          },
        ],
      },
      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          width: 45,
          customRender: (value, item, index) => {
            return item.isTotal ? "合计" : index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "姓名",
          dataIndex: "employee_name",
          key: "employee_name",
        },
        {
          title: "工作日期",
          dataIndex: "work_date",
          key: "work_date",
        },
        
        {
          title: "日薪",
          dataIndex: "salary_day",
          key: "salary_day",
          customRender: (value, item) => {
            if (item.isTotal) return item.totalAmount;
            return item.salary_day;
          },
        },
        
        {
          title: "类型",
          dataIndex: "work_type",
          key: "work_type",
          customRender: (value, item) => {
            if (!item.isTotal){
              if (item.employee_type=='permanent'){
              return item.work_type=='Attendance' ? "加班" : '缺勤';
            }else{
              return '出勤'
            }
            }
            
            }
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "用户备注",
          dataIndex: "remark",
          key: "remark",
        },
      ],
      productOrderItems: [],
    };
  },
  computed: {
    OrderTotalAmount() {
      let totalAmount = 0;
      for (let item of this.productOrderItemsSelect) {

      totalAmount = NP.plus(totalAmount, item.salary_day);
      }
      totalAmount = NP.plus(totalAmount, this.form.other_amount || 0);
      return totalAmount;
    },
    productOrderItemsSelect() {
      return this.productOrderItems.filter((item) =>
        this.selectedRowKeys.includes(item.id)
      );
    },

    productsData() {
      // 统计合计
      // return []
      let totalAmount = 0
     
      for (let item of this.productOrderItemsSelect) {

        totalAmount = NP.plus(totalAmount, item.salary_day);
      }
      return [
        ...this.productOrderItems,
        {
          id: "-1",
          isTotal: true,
          name: "",
          totalAmount,
        },
      ];
    },
  },
  methods: {
    moment,
    initData() {
      var employee_id = this.$route.query.employeeId;
      this.resetForm();
      employeeOption({ page_size: 999999, is_active: true }).then((data) => {
        this.employee = data.results.find((item) => item.id == employee_id);
        if (this.employee == undefined) {
          this.$message.error("该员工不存在或者被禁止了");
        } else {
          console.log(this.employee);
          this.get_work_list();
        }
      });
    },
    get_work_list() {
      this.tb_loading = true;
      unpaidWorkList({
        page_size: 999999,
        employee: this.employee.id,
      })
        .then((data) => {
          this.productOrderItems = data;
        })
        .finally(() => {
          this.tb_loading = false;
        });
    },

    onSelectChange(selectedRowKeys) {
      this.selectedRowKeys = selectedRowKeys;
    },

    create() {
      const validOrderId = this.selectedRowKeys.filter((key) => key !== "-1");
      if (validOrderId.length === 0) {
        this.$message.error("请至少选择一个订单进行付款");
        return;
      }
      let payment_images = this.form.image_items.map((item) => {
        return item.response.id;
      });

      if (payment_images.length == 0) {
        this.$message.error("请提供付款凭证");
        return;
      }
      if (this.collection_amount <= 0) {
        this.$message.error("付款金额为空");
        return;
      }
      // if (this.collection_amount != this.OrderTotalAmount) {
      //   this.$message.error("付款金额与订单金额不一致");
      //   return;
      // }


      this.loading = true;
      let {number, other_amount,remark} = this.form;
      let formData = {
        number,
        other_amount,
        remark,
        employee: this.employee.id,
        total_paid_amount: this.collection_amount,
        total_arrears_amount: this.OrderTotalAmount,
        payment_images,
        payment_orders: validOrderId,
      };
      console.log("formData", formData);
      salaryRecordsCreate(formData)
        .then((data) => {
          this.$message.success("创建成功");
          this.$router.push({
            path: "/hrms/payment_salary_view",
            query: { employeeId: this.employee.id },
          });
        }) .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
        .finally(() => {
          this.loading = false;
        });
    },

    async handlePreview(file) {
      console.log(file);
      if (!file.url && !file.preview) {
        file.preview = await this.$functions.getBase64(file.originFileObj);
      }
      this.previewImage = file.url || file.preview;
      this.previewVisible = true;
    },

    handleChange({ fileList }) {
      console.log("fileList", fileList);
      // 过滤出上传完成的文件
      const completedFiles = fileList.filter((file) => file.status === "done");

      // 如果需要，处理这些文件的response数据
      completedFiles.forEach((file) => {
        if (file.response) {
          console.log("Upload success response:", file.response);
        }
      });
      this.$set(this.form, "image_items", fileList);
    },

    handleCancel() {
      this.previewVisible = false;
    },

    resetForm() {
      this.form = { image_items: [], other_amount: 0 };
      // this.form = {other_amount: 0 };
      getSalaryRecordsNumber().then((data) => {
        this.form = { ...this.form, number: data.number };
      });
    },

    downloadOrders() {
      let dataToDownload = this.productOrderItemsSelect;

      if (dataToDownload.length === 0) {
        this.$message.error("请选择至少一个订单进行下载");
        return;
      }
       // 检查并添加合计行
    // 假设合计行已经通过某种方式标记或者可以通过特定条件识别
    const hasTotalRow = dataToDownload.some(item => item.isTotal);
    if (!hasTotalRow) {
      // 如果当前选择的订单中没有合计行，你可以选择添加或不添加
      // 例如，重新从 productsData 获取合计行
      const totalRow = this.productsData.find(item => item.isTotal);
      if (totalRow) {
        dataToDownload.push(totalRow);
      }
    }

      // 获取当前客户的名称和当前日期时间作为文件名
      const customerName = this.employee.name; // 假设客户名称存储在 employee 对象的 name 属性中
      const dateTime = moment().format("YYYYMMDD_HHmmss"); // 格式化当前时间
      const fileName = `${customerName}_${dateTime}_unpaid.xlsx`; // 组合文件名

      // 使用 SheetJS 创建工作簿
      const ws = XLSX.utils.json_to_sheet(dataToDownload);
      const wb = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(wb, ws, "Orders");

      // 生成 XLSX 文件并保存
      XLSX.writeFile(wb, fileName); // 使用动态文件名
    },
  },
  mounted() {
    console.log("hallo");
    this.initData();
  },
};
</script>

<style scoped>
.highlight-cell {
  background-color: yellow; /* 或者使用更具体的颜色代码，如 #ffff00 */
}
</style>