<template>
  <div>
    <a-row gutter="12">
      <a-col :span="24">
        <production-task/>
      </a-col>
      <a-col :span="24">
        <a-col :span="12">
          <stockm-in-task style="margin-top: 5px;"/>
        </a-col>
        <a-col :span="12">
          <inventorym-warnings style="margin-top: 5px;"/>
        </a-col>
      </a-col>
      <a-col :span="24" style="margin-top: 5px;">
        <material-production/>
      </a-col>
    </a-row>
  </div>
</template>

<script>
  export default {
    name: 'Home',
    components: {
      // SalesTrend: () => import('./salesTrend.vue'),
      ProductionTask: () => import('./productionTask.vue'),

      // 物料出库入库
      // StockmOutTask: () => import('./stockmOutTask.vue'),
      StockmInTask: () => import('./stockmInTask.vue'),

      //产品出库入库
      // StockOutTask: () => import('./stockOutTask.vue'),
      // StockInTask: () => import('./stockInTask.vue'),
      // SalesGoods: () => import('./salesGoods.vue'),
      InventorymWarnings: () => import('./inventorymWarnings.vue'),
      MaterialProduction: () => import('./MaterialProduction.vue'),
    },
  }
</script>