import system from "./modules/system";
import user from "./modules/user";
import table_filters from "./modules/table_filters";
import table_scroll from "./modules/table_scroll";
import Vuex from "vuex";
import Vue from "vue";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    system,
    table_filters,
    table_scroll
  },
});
