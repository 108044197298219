<template>
  <div>
    <a-card
      :tab-list="tabList"
      :active-tab-key="currentTab"
      @tabChange="changeTab"
    >
      <a-row gutter="16">
        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-input
            v-model="searchForm.search"
            placeholder="编号, 名称, 备注"
            allowClear
            @pressEnter="search"
          />
        </a-col>

        <a-col :span="24" style="max-width: 200px; margin-bottom: 12px">
          <a-select
            placeholder="员工选择"
            v-model="searchForm.employee"
            allowClear
            @change="search"
            style="width: 200px"
          >
            <a-select-option
              v-for="option in employeeItems"
              :key="option.id"
              :value="option.id"
            >
              <a-tooltip :title="option.name">
                <span>{{ option.name }}</span>
              </a-tooltip>
            </a-select-option>
          </a-select>
        </a-col>

        <a-col :span="24" style="width: 100px; margin-bottom: 12px">
          <a-button type="primary" icon="search" @click="search">查询</a-button>
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="openFormModal(form)"
            >新增记录</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          rowKey="id"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button
                icon="edit"
                size="small"
                @click="openFormModalUpdate(item)"
                :disabled="!!item.payment_number"
                >编辑</a-button
              >
              <a-popconfirm title="确定删除吗" :disabled="!!item.payment_number" @confirm="destroy(item)">
                <a-button type="danger" icon="delete" size="small"
                :disabled="!!item.payment_number">
                  >删除</a-button
                >
              </a-popconfirm>
            </a-button-group>
          </div>
        </a-table>
      </a-row>
    </a-card>

    <form-modal
      ref="childRef"
      v-model="visible"
      :form="targetItem"
      :employeeItems="employeeItems"
      :currentTab="currentTab"
      @create="create"
    />

    <form-modal-update
      v-model="visibleUpdate"
      :form="targetItem"
      :currentTab="currentTab"
      @update="update"
    >
      <!-- 组件内部内容 -->
    </form-modal-update>
  </div>
</template>

<script>

import { workRecordsList, workRecordsDestroy } from "@/api/hrms";
import { employeeOption } from "@/api/option";

export default {
  name: "WorkRecords",
  components: {
    FormModal: () => import("./FormModal.vue"),
    FormModalUpdate: () => import("./FormModalUpdate.vue"),
  },
  data() {
    return {
      currentTab: "temporary",
      tabList: [
        {
          key: "temporary",
          tab: "临时工考勤",
        },
        {
          key: "permanent",
          tab: "正式工考勤",
        },
      ],

      columns: [
        {
          title: "序号",
          dataIndex: "index",
          key: "index",
          customRender: (value, item, index) => {
            return index + 1;
          },
        },
        {
          title: "编号",
          dataIndex: "number",
          sorter: true,
        },
        {
          title: "员工姓名",
          dataIndex: "employee_name",
        },
        {
          title: "日薪",
          dataIndex: "salary_day",
        },
        {
          title: "工作日期",
          dataIndex: "work_date",
          sorter: true,
        },
        {
          title: "结算编号",
          dataIndex: "payment_number",
        },
        // {
        //   title: "是否结算",
        //   dataIndex: "is_paid",
        //   customRender: (value, item, index) => {
        //     return value ? "是" : "否";
        //   },
        // },
        {
          title: "类型",
          dataIndex: "work_type",
          customRender: (value, item, index) => {
            return value === "Attendance" ? "出勤" : "缺勤";
          },
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
        },
        {
          title: "创建人",
          dataIndex: "creator_name",
        },
        {
          title: "备注",
          dataIndex: "remark",
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          width: "156px",
        },
      ],
      searchForm: { search: "", page: 1, page_size: 16,},
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      employeeItemsAll: [],
      visible: false,
      visibleUpdate: false,
      targetItem: {},
      form: {},
    };
  },
  computed: {
    employeeItems() {
      // 根据 currentTab 的值决定使用哪个数组
      if (this.currentTab === 'temporary') {
        return this.temporaryEmployeeItems;
      } else if (this.currentTab === 'permanent') {
        return this.permanentEmployeeItems;
      }
      return []; // 如果 currentTab 没有有效值，返回空数组
    },
    temporaryEmployeeItems() {
      // 过滤出临时员工
      return this.employeeItemsAll.filter(
        (item) => item.employee_type === "temporary"
      );
    },
    permanentEmployeeItems() {
      // 过滤出永久员工
      return this.employeeItemsAll.filter(
        (item) => item.employee_type === "permanent"
      );
    },
  },
  created() {},
  methods: {
    initialize() {
      employeeOption({ page_size: 999999, is_active: true }).then((resp) => {
        this.employeeItemsAll = resp.results;
      });
      this.resetForm(this.currentTab);
      this.list();
    },
    list() {
      this.loading = true;
      workRecordsList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    update(item) {
      console.log("ss", item);
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    openFormModal(item) {
      console.log("d");
      this.targetItem = {};
      this.visible = true;
    },

    openFormModalUpdate(item) {
      this.targetItem = { ...item };
      this.visibleUpdate = true;
    },
    destroy(item) {
      const id = item.id;
      if (item.is_paid) {
        this.$message.error("已支付订单,无法删除");
        return;
      }
      workRecordsDestroy({ id }).then(() => {
        // this.items.splice(this.items.findIndex(item => item.id == id), 1);
        this.$message.success("删除成功");
        this.list();
      });
    },

    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${
        sorter.field
      }`;
      this.list();
    },

    changeTab(key) {
      this.currentTab = key;
      // this.$router.replace({ query: { type: this.currentTab } });
      this.resetForm(key);
      this.search();
    },

    resetForm(key) {
      this.searchForm = {
        page: 1,
        page_size: 16,
      };
      this.searchForm['employee_type']= key;

    },

    create(item) {
      // this.items.splice(0, 0, item);
      this.list();
    },
  },
  mounted() {
    this.initialize();
  },
};
</script>