<template>
  <div>
      <a-row>
        <a-card title="完全备货的订单">
          <a-table
            :columns="fullyStockedColumns"
            :dataSource="fullyStockedItems"
            :loading="loading"
            :row-key="record => record.order_number"
            :pagination="false"
          />
        </a-card>
      </a-row>
      <a-card title="生产物料采购">
      <a-row style="margin-top: 12px">
        <a-table
          size="small"
          :columns="columns"
          :dataSource="items"
          :row-key="record => record.material_number"
          :loading="loading"
          :pagination="false"
        >
          <template v-slot:expandedRowRender="record">
            <a-table
              :columns="detailsColumns"
              :data-source="record.production_details"
              pagination="false"
            >
            </a-table>
            <a-table
              :columns="orderColumns"
              :data-source="record.order_details"
              pagination="false"
            >
            </a-table>
          </template>
        </a-table>
        <div style="text-align: center; margin-top: 16px">
          <a-pagination
            v-model="currentPage"
            :total="totalRows"
            :pageSize="totalRows"
            show-less-items
            :show-total="(total) => `总条数 ${total}`"
          />
        </div>
      </a-row>
    </a-card>
  </div>
</template>
  <script>
import { purchaseMaterialsList } from "@/api/purchasing";

export default {
  name: "Warehouse",
  data() {
    return {

      fullyStockedColumns: [
        { title: "订单编号", dataIndex: "number", key: "number" },
        { title: "产品名称", dataIndex: "good_name", key: "good_name" }
      ],
      fullyStockedItems: [],  // 新增
      columns: [
        { title: "物料编号", dataIndex: "material_number", key: "material_number" },
        { title: "物料名称", dataIndex: "material_name", key: "material_name" },
        { title: "总需求", dataIndex: "total_demand", key: "total_demand" },
        { title: "现有库存", dataIndex: "current_stock", key: "current_stock" },
        { title: "在途采购", dataIndex: "on_order", key: "on_order" },
        { title: "短缺", dataIndex: "shortage", key: "shortage" }
      ],
      detailsColumns: [
        { title: "订单编号", dataIndex: "order_number", key: "order_number" },
        { title: "所需数量", dataIndex: "quantity_needed", key: "quantity_needed" },
        { title: "生产数量", dataIndex: "production_quantity", key: "production_quantity" },
        { title: "产品名称", dataIndex: "product_name", key: "product_name" }
      ],
      orderColumns: [
        { title: "采购单编号", dataIndex: "stock_order_number", key: "stock_order_number" },
        { title: "采购数量", dataIndex: "quantity", key: "quantity" },
        { title: "状态", dataIndex: "status", key: "status"},
        { title: "是否完成", dataIndex: "is_completed", key: "is_completed", customRender: this.renderCompleted },
        { title: "预计到货", dataIndex: "expected_date", key: "expected_date" },
        { title: "供应商名称", dataIndex: "supplier_name", key: "supplier_name" }
      ],
      loading: false,
      items: [],
      currentPage: 1,
      totalRows: 0,
      pageSize: 10, // 或其他适当的页面大小
    };
  },
  methods: {
    customRender(dataIndex, groupBy) {
      return (value, row, index) => {
        const obj = {
          children: value,
          attrs: {},
        };
        // 如果为指定的三个字段且其值为空，则不进行合并
        if (
          [
            "stock_order_number",
            "purchase_supplier_name",
            "original_stock_in_quantity",
          ].includes(groupBy) &&
          !row[groupBy]
        ) {
          obj.attrs.rowSpan = 1;
          return obj;
        }
        const rows = this.items.filter(
          (item) => item[groupBy] === row[groupBy]
        );
        if (rows.length > 1 && row.order_number !== rows[0].order_number) {
          obj.attrs.rowSpan = 0;
        } else {
          obj.attrs.rowSpan = rows.length;
        }
        return obj;
      };
    },
    list() {
      this.loading = true;
      purchaseMaterialsList()
        .then((data) => {
          // 假设返回的数据已经按照 material_number 排序
        
          console.log(data);
          this.items = data.material_analysis;
          this.fullyStockedItems = data.fully_stocked_orders; // 假设 API 同样返回这部分数据
          this.loading = false;
          this.totalRows = this.items.length; // 假设 API 返回总条目数
        })
        .catch((error) => {
          console.error("Error fetching data:", error);
          this.loading = false;
        });
    },
  },
  mounted() {
    this.list();
  },
};
</script>
  
  <style scoped>
/* Your custom styles here */
</style>
  