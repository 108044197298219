export default {
  state: () => ({
    tableScrollPosition: 0,
  }),
  mutations: {
    setTableScrollPosition(state, position) {
      state.tableScrollPosition = position;
    }
  },
  actions: {
    updateTableScrollPosition({ commit }, position) {
      commit('setTableScrollPosition', position);
    }
  }
}