<template>
  <div>
    <a-card style="height: calc(80vh - 30px)">
      <div slot="title" style="height: 10px; margin-bottom: 5px">
        <span>生产任务提醒</span>
      </div>
      <!-- <a-button slot="extra" @click="exportExcelMaterials"
        >下载数据(物料不足)</a-button
      > -->
      <a-button slot="extra" icon="sync" @click="initialize">刷新</a-button>
      <a-button slot="extra" @click="downloadExcel">下载数据</a-button>
      <a-button
        slot="extra"
        type="primary"
        style="margin-right: 8px"
        v-print="'#printContent'"
        @click="showPreview"
      >
        <a-icon type="printer" />打印</a-button
      >
      <!-- <a-button style="margin-top: 0px" type="primary" @click="showPreview"> 生成生产单 </a-button> -->
      <a-table
        ref="tableContainer"
        :rowKey="(record) => record.number"
        @change="onChangeFitter"
        :columns="computedColumns"
        :rowSelection="rowSelection"
        :data-source="currentPageData"
        size="small"
        :pagination="false"
        :loading="loading"
        :scroll="{ y: tableHeight }"
      >
        <template slot="image" slot-scope="value, record">
          <div>
            <!-- 首先检查数组是否存在且不为空 -->
            <div
              v-if="
                record.goods_image_items && record.goods_image_items.length > 0
              "
            >
              <!-- 接下来检查第一项是否为特殊值 'no_good' -->
              <div v-if="record.goods_image_items[0] === 'no_good'">
                未匹配到产品
              </div>
              <!-- 如果不是 'no_good'，则显示图片 -->
              <img
                v-else
                :src="record.goods_image_items[0].file"
                alt="Preview"
                @click="showImages(record.goods_image_items)"
                style="width: 50px; cursor: pointer"
              />
            </div>
            <!-- 如果数组不存在或为空，也显示文本 -->
            <div v-else>未上传图片</div>
          </div>
        </template>

        <template slot="cell" slot-scope="text, record">
          <a-tooltip :title="text">
            <span
              style="
                display: inline-block;
                width: 100%;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
              "
            >
              {{ text ? text.slice(0, 20) : "" }}
            </span>
          </a-tooltip>
        </template>

        <div slot="date" slot-scope="value">
          {{ moment(value).format("YYYY-MM-DD") }}
        </div>

        <template slot="handle_date" slot-scope="value, record,index">
          <div v-if="editIndex !== index">
            {{ value || '设置日期' }}
            <a-icon type="edit" @click="startEdit(index)" />
          </div>
        <div v-else>
          <a-date-picker v-model="editDate" 
          value-format="YYYY-MM-DD"
          @change="onDateChange" />
          <a-icon type="check" @click="saveEdit(record, index)" />
          <a-icon type="close" @click="cancelEdit" />
        </div>
    </template>



        <div slot="action" slot-scope="value, item">
          <a-button-group>
            <a-button type="link" @click="viewTask(item)">编辑</a-button>
            <a-button
              type="link"
              v-if="item.goods"
              @click="viewTaskProcess(item)"
              >生产进度</a-button
            >
          </a-button-group>
        </div>

        <template slot="bm_remark" slot-scope="text, record, index">
          <!-- <a-input
            v-model="record.bm_remark"
            @blur="handleSave(index, record)"
            placeholder="请输入追踪号"
          /> -->
          <a-tooltip :title="record.bm_remark">
            <a-input
              v-model="record.bm_remark"
              @blur="handleSave(index, record)"
              placeholder="请输入备注"
            />
          </a-tooltip>
        </template>
      </a-table>
      <div style="text-align: center; margin-top: 16px">
        <a-pagination
          v-model="currentPage"
          :total="totalRows"
          :pageSize="totalRows"
          show-less-items
          :show-total="(total) => `总条数 ${total}`"
        />
      </div>

      <!-- 图片浏览模态框 -->
      <a-modal
        title="Image Gallery"
        :visible="isModalVisible"
        @cancel="handleCancel"
        width="60%"
        footer="null"
      >
        <a-carousel effect="fade" v-model="currentImageIndex">
          <div v-for="(img, index) in images" :key="index">
            <img
              :src="img.file"
              style="width: 100%; height: auto"
              :alt="img.name"
            />
          </div>
        </a-carousel>
      </a-modal>
    </a-card>
    <a-modal
      title="预览生产单"
      :visible="isModalVisibleOrder"
      @ok="printOrder"
      @cancel="handleCancelOrder"
      class="a4-modal"
    >
      <div ref="content" class="print-content">
        <table class="production-task-table">
          <thead v-if="processedItems.store_name">
            <tr>
              <th colspan="9">生产任务单</th>
            </tr>
            <tr>
              <th colspan="2">下单用户</th>
              <th colspan="2">{{ processedItems.bm_user_name }}</th>
              <th colspan="2">店铺名称</th>
              <th colspan="3">{{ processedItems.store_name }}</th>
            </tr>
            <tr>
              <th>序号</th>
              <th>产品图片</th>
              <th>产品型号</th>
              <th>电芯型号</th>
              <th>数量</th>
              <th>单位</th>
              <th>交货日期</th>
              <th>备注</th>
            </tr>
          </thead>

          <thead v-else-if="processedItems.goods_name">
            <tr>
              <th colspan="9">生产任务单</th>
            </tr>
            <tr>
              <th colspan="1">产品名称</th>
              <th colspan="1">{{ processedItems.goods_name }}</th>

              <th colspan="1">电芯型号</th>
              <th colspan="2">{{ processedItems.battery_cell }}</th>
              <td colspan="2">
                <img
                  :src="processedItems.firstImage"
                  alt="产品图片"
                  style="width: 80px"
                />
              </td>
            </tr>
            <tr>
              <th>序号</th>
              <th>用户</th>
              <th>店铺</th>
              <th>数量</th>
              <th>单位</th>
              <th>交货日期</th>
              <th>备注</th>
            </tr>
          </thead>

          <thead v-else>
            <tr>
              <th>序号</th>
              <th>图片</th>
              <th>店铺</th>
              <th>用户</th>
              <th>产品型号</th>
              <th>电芯型号</th>
              <th>数量</th>
              <th>单位</th>
              <th>交货日期</th>
              <th>备注</th>
            </tr>
          </thead>

          <tbody v-if="processedItems.store_name">
            <tr v-for="(item, index) in processedItems.items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <img
                  :src="item.goods_image_items[0].file"
                  alt="产品图片"
                  style="width: 80px"
                />
              </td>
              <td>{{ item.goods_name }}</td>
              <td>{{ item.battery_cell }}</td>
              <td>{{ item.quantity }}</td>
              <td>个</td>
              <td>{{ item.handle_date }}</td>
              <td>{{ item.remark }}</td>
            </tr>
          </tbody>

          <tbody v-else-if="processedItems.goods_name">
            <tr v-for="(item, index) in processedItems.items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>{{ item.bm_user_name }}</td>
              <td>{{ item.store_name }}</td>
              <td>{{ item.quantity }}</td>
              <td>个</td>
              <td>{{ item.handle_date }}</td>
              <td>{{ item.remark }}</td>
            </tr>
          </tbody>

          <tbody v-else>
            <tr v-for="(item, index) in processedItems.items" :key="index">
              <td>{{ index + 1 }}</td>
              <td>
                <img
                  :src="item.goods_image_items[0].file"
                  alt="产品图片"
                  style="width: 80px"
                />
              </td>
              <td>{{ item.bm_user_name }}</td>
              <td>{{ item.store_name }}</td>
              <td>{{ item.goods_name }}</td>
              <td>{{ item.battery_cell }}</td>
              <td>{{ item.quantity }}</td>
              <td>个</td>
              <td>{{ item.handle_date }}</td>
              <td>{{ item.remark }}</td>
            </tr>
          </tbody>
        </table>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { productionTaskAll } from "@/api/system";
import moment from "moment";
import { materialsPurchaseExport } from "@/api/export";
import { productionOrderUpdate } from "@/api/production";
import * as ExcelJS from "exceljs";
import { saveAs } from "file-saver";
import { exportExcel } from "@/utils/excel";
export default {
  name: "WarehouseTask",
  data() {
    return {
      moment,
      filteredInfo: {},

      editIndex: -1,
      editDate: '',

      // taskType: null,
      allItems: [], // 存储从后端获取的所有数据
      currentPageData: [], // 当前页面显示的数据
      perPage: 10, // 每页显示的数据量
      totalRows: 0, // 总数据量
      currentPage: 1, // 当前页码

      //勾选框
      isModalVisibleOrder: false,
      selectedItems: [],
      rowSelection: {
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedItems = selectedRows;
          //   item.goods_image_items[0] = 'http://factory.bornmieo.com/media/CS-DJG300RC-1_wWjPrQu.jpg';
          // })
          // console.log(
          //   "selectedRowKeys:",
          //   selectedRowKeys,
          //   "selectedRows:",
          //   selectedRows
          // );
        },
        type: "checkbox", // 使用复选框
      },
      statusMap: {
        Analysis: "待生产",
        Purchase: "物料采购",
        InProduction: "生产中",
        Complete: "生产完成",
        Shipped: "已发货",
        Cancel: "取消",
      },
      searchForm: {
        is_done: false,
        statues_fitter: true,
      },
      loading: false,

      statusList: [
        {
          id: 1,
          code: "Analysis",
          name: "待生产",
        },
        // {
        //   id: 2,
        //   code: "SamplePurchase",
        //   name: "样品采购",
        // },
        {
          id: 3,
          code: "Purchase",
          name: "物料采购",
        },
        {
          id: 4,
          code: "InProduction",
          name: "生产中",
        },
        {
          id: 5,
          code: "Complete",
          name: "已完成",
        },
        // {
        //   id: 6,
        //   code: "Shipped",
        //   name: "已发货",
        // },
        // {
        //   id: 7,
        //   code: "Cancel",
        //   name: "取消",
        // },
      ],
      //  图片相关
      isModalVisible: false,
      images: [],
      currentImageIndex: 0,

      columns: [
        {
          title: "编号",
          dataIndex: "number",
          key: "number",
        },
        {
          title: "用户",
          dataIndex: "bm_user_name",
          key: "bm_user_name",
          width: "80px",
          onFilter: (value, record) => record.bm_user_name === value,
        },
        {
          title: "店铺",
          dataIndex: "store_name",
          key: "store_name",
          width: "80px",
          onFilter: (value, record) => record.store_name === value,
        },
        {
          title: "cs名称",
          dataIndex: "product_name",
          key: "product_name",
        },
        {
          title: "bm名称",
          dataIndex: "goods_name",
          key: "goods_name",
          onFilter: (value, record) => record.goods_name === value,
        },
        {
          title: "电芯",
          dataIndex: "battery_cell",
          key: "battery_cell",
          // scopedSlots: { customRender: "cell" },
          onFilter: (value, record) => record.battery_cell === value,
        },
        {
          title: "Image",
          key: "image",
          scopedSlots: { customRender: "image" },
        },
        {
          title: "订购数量",
          dataIndex: "quantity",
          key: "quantity",
        },
        // {
        //   title: "单价",
        //   dataIndex: "sale_price",
        //   key: "sale_price",
        // },
        // {
        //   title: "下单金额",
        //   dataIndex: "total_amount",
        //   key: "total_amount",
        //   width:"40px"
        // },
        {
          title: "状态",
          dataIndex: "statues",
          key: "statues",
          customRender: (text) => {
            const statusItem = this.statusList.find(
              (status) => status.code === text
            );
            return statusItem ? statusItem.name : "未知状态";
          },
          onFilter: (value, record) => record.statues === value,
        },
        {
          title: "数量",
          dataIndex: "quantity_",
          key: "quantity_",
          customRender: (value, item) => {
            if (["InProduction", "Complete"].includes(item.statues)) {
              var quantity_ = item.actual_quantity;
            } else if (item.statues == "Shipped") {
              var quantity_ = item.shipment_quantity;
            } else {
              var quantity_ = item.quantity;
            }
            if (quantity_ == null) {
              var quantity_ = item.quantity;
            }
            return quantity_;
          },
        },
        {
          title: "期望交货时间",
          dataIndex: "handle_date",
          key: "handle_date",
          scopedSlots: { customRender: 'handle_date' },
          // width:'110px'
          onFilter: (value, record) => record.handle_date === value,
        },
        {
          title: "更新时间",
          dataIndex: "update_time",
          key: "update_time",
          // width:'110px'
        },
        {
          title: "创建时间",
          dataIndex: "create_time",
          key: "create_time",
        },
        {
          title: "工厂备注",
          dataIndex: "bm_remark",
          key: "remark",
          scopedSlots: { customRender: "bm_remark" },
          ellipsis: true,
        },
        {
          title: "客户备注",
          dataIndex: "remark",
          key: "remark",
          // width: "30px",
          ellipsis: true,
        },
        {
          title: "操作",
          dataIndex: "action",
          scopedSlots: { customRender: "action" },
          // fixed: 'right',
        },
      ],
      tableHeight: window.innerHeight / 1.5 - 150,
    };
  },

  computed: {
    computedColumns() {
      let { filteredInfo } = this;
      filteredInfo = filteredInfo || {};
      return this.columns.map((col) => {
        if (
          [
            "bm_user_name",
            "store_name",
            "statues",
            "goods_name",
            "battery_cell",
            "handle_date",
          ].includes(col.dataIndex)
        ) {
          const counts = {};
          this.currentPageData.forEach((item) => {
            const key = item[col.dataIndex];
            if (key) {
              // 确保 key 存在
              counts[key] = (counts[key] || 0) + 1;
            }
          });

          let filters = Object.entries(counts).map(([value, count]) => ({
            text: `${value} (${count})`,
            value: value,
          }));

          if (col.dataIndex === "statues") {
            filters = filters.map((filter) => ({
              text: `${this.statusMap[filter.value] || "未知"} (${
                counts[filter.value]
              })`,
              value: filter.value,
            }));
          }
          if (col.dataIndex === "handle_date") {
            // console.log('filters',filters)
            filters = filters.sort(function (a, b) {
              // 如果要升序排列return后方就用 a.age - b.age
              // 如果要降序排列return后方就用 b.age - a.age
              return new Date(a.value) - new Date(b.value);
              // return a.handle_date - b.handle_date
            });
            // console.log('new filters',filters)
          }
          const filteredValue = filteredInfo[col.dataIndex] || null;
          return { ...col, filters, filteredValue };
        }
        return col;
      });
    },
    processedItems() {
      const storeNames = new Set(
        this.selectedItems.map((item) => item.store_name)
      );
      // 如果 set 的大小为 1 且不包含空字符串，则认为所有店铺相同
      const allStoresSame = storeNames.size === 1 && !storeNames.has("");

      const goodsNames = new Set(
        this.selectedItems.map((item) => item.goods_name)
      );
      // 如果 set 的大小为 1 且不包含空字符串，则认为所有商品名称相同
      const allGoodsSame = goodsNames.size === 1 && !goodsNames.has("");
      let groups = {};
      let groupKey;
      if (allStoresSame) {
        this.selectedItems.forEach((item) => {
          // 使用模板字符串构建 groupKey
          groupKey = `${item.bm_user_name}-${item.store_name}`;
          if (!groups[groupKey]) {
            groups[groupKey] = {
              bm_user_name: item.bm_user_name,
              store_name: item.store_name,
              items: [],
            };
          }
          groups[groupKey].items.push(item);
        });
        return groups[groupKey];
      } else if (allGoodsSame) {
        this.selectedItems.forEach((item) => {
          // 商品名称作为键
          groupKey = item.goods_name;
          if (!groups[groupKey]) {
            groups[groupKey] = {
              goods_name: item.goods_name,
              battery_cell: item.battery_cell,
              firstImage: item.goods_image_items[0]?.file || "",
              items: [],
            };
          }
          groups[groupKey].items.push(item);
        });
        return groups[groupKey]; // 转换对象为数组以便于在模板中遍历
      } else {
        return { items: this.selectedItems }; // 保持数据结构一致性
      }
    },
  },

  methods: {
    // initialize() {
    //   this.list();
    // },
    initialize() {
      this.loading = true;
      return productionTaskAll(this.searchForm)
        .then((response) => {
          this.allItems = response.results.map((item) => {
            return { ...item, original_bm_remark: item.bm_remark };
          });
          // this.allItems = response.results;
          this.totalRows = response.count;
          this.perPage = response.per_page;
          this.currentPageData = this.allItems;
          this.init_filtered_info();
        })
        .finally(() => {
          this.loading = false;
        });
    },
    showPreview() {
      if (this.selectedItems.length == 0) {
        this.$message.error("请先选择数据");
        return;
      }

      this.isModalVisibleOrder = true;
    },
    handleCancelOrder() {
      this.isModalVisibleOrder = false;
    },

    printOrder() {
      console.log("Start Print...");
      const content = this.$refs.content; // 使用ref引用元素，而不是直接通过id
      console.log(content);
      window.print();
    },

    viewTask(item) {
      // this.$router.push({ path: '/purchasing/purchase_record_detail', query: { id: item.id } });
      this.$router.push({
        path: "/production/production_record_detail",
        query: { search: item.id },
      });
    },

    viewTaskProcess(item) {
      this.$router.push({
        path: "/production/production_task_process",
        query: {
          search: item.id,
          goods: item.goods,
        },
      });
    },

    init_page_data(filters) {
      console.log(filters);
      this.currentPageData = this.allItems.filter((item) => {
        return Object.entries(filters).every(([key, value]) => {
          return (
            value == null ||
            value.length === 0 ||
            value.includes(item[key].toString())
          );
        });
      });
      this.totalRows = this.currentPageData.length;
      console.log("totalRows", this.totalRows);
    },

    onChangeFitter(pagination, filters, sorter) {
      console.log("params", pagination, filters, sorter);
      this.filteredInfo = filters;
      this.$store.dispatch("updateTableFilters", filters);
      this.init_page_data(filters);
    },

    //图片相关的方法
    showImages(images) {
      this.images = images;
      this.isModalVisible = true;
    },
    handleCancel() {
      this.isModalVisible = false;
    },
    init_filtered_info() {
      let filteredInfo = this.$store.state.table_filters.tableFilters;
      console.log("初始化filteredInfo", filteredInfo);
      this.filteredInfo = filteredInfo;
      this.init_page_data(filteredInfo);
    },
    //滚动条部分
    handleScroll(event) {
      const scrollTop = event.target.scrollTop;
      // console.log("scrollTop---", scrollTop);
      this.$store.dispatch("updateTableScrollPosition", scrollTop);
    },
    setScrollPosition() {
      const tableBody =
        this.$refs.tableContainer.$el.querySelector(".ant-table-body");
      if (tableBody) {
        console.log(
          "恢复滚动位置",
          this.$store.state.table_scroll.tableScrollPosition
        );
        tableBody.scrollTop =
          this.$store.state.table_scroll.tableScrollPosition;
      }
    },
    addScrollListener() {
      const tableBody =
        this.$refs.tableContainer.$el.querySelector(".ant-table-body");
      if (tableBody) {
        tableBody.addEventListener("scroll", this.handleScroll);
      }
    },

    exportExcelMaterials() {
      materialsPurchaseExport()
        .then((resp) => {
          // console.log(resp);
          exportExcel(resp.data, "物料不足信息列表");
        })
        .catch((err) => {
          this.$message.error(err.response.data.error);
        });
    },

    async convertImageToBase64(url) {
      const response = await fetch(url, {});
      const blob = await response.blob();
      return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onloadend = () => resolve(reader.result); // Base64 数据
        reader.onerror = reject;
        reader.readAsDataURL(blob);
      });
    },

    async downloadExcel() {
      const workbook = new ExcelJS.Workbook();
      const worksheet = workbook.addWorksheet("My Sheet");

      // 定义列标题和宽度
      worksheet.columns = [
        { header: "编号", key: "number", width: 10 },
        { header: "用户", key: "bm_user_name", width: 20 },
        { header: "店铺", key: "store_name", width: 20 },
        { header: "cs名称", key: "product_name", width: 20 },
        { header: "bm名称", key: "goods_name", width: 20 },
        { header: "电芯", key: "battery_cell", width: 15 },
        { header: "图片", key: "image", width: 15 },
        { header: "订购数量", key: "quantity", width: 10 },
        { header: "单价", key: "sale_price", width: 10 },
        { header: "下单金额", key: "total_amount", width: 15 },
        { header: "状态", key: "statues", width: 15 },
        { header: "数量", key: "quantity_", width: 10 },
        { header: "期望交货时间", key: "handle_date", width: 20 },
        { header: "更新时间", key: "update_time", width: 20 },
        { header: "创建时间", key: "create_time", width: 20 },
        { header: "备注", key: "remark", width: 15 },
      ];
      let rowIndex = 1; // 初始行索引
      for (const row of this.currentPageData) {
        const base64String = await this.convertImageToBase64(
          row.goods_image_items[0].file
        );
        const imageId = workbook.addImage({
          base64: base64String,
          extension: "png",
        });
        const newRow = worksheet.addRow({
          number: row.number,
          bm_user_name: row.bm_user_name,
          store_name: row.store_name,
          product_name: row.product_name,
          goods_name: row.goods_name,
          battery_cell: row.battery_cell,
          image: "",

          quantity: row.quantity,
          sale_price: row.sale_price,
          total_amount: row.total_amount,
          statues: row.statues,
          quantity_: row.quantity_,
          handle_date: row.handle_date,
          update_time: row.update_time,
          create_time: row.create_time,
          remark: row.remark,
        });
        // 增加行后，rowIndex 应更新为新行的索引
        rowIndex = newRow.number;
        newRow.height = 70; // 设置更高的行高以适应图片
        newRow.alignment = { vertical: "middle", horizontal: "center" };
        // 计算图片位置：例如，假设图片应该放在 E 列（第 6 列）
        worksheet.addImage(imageId, {
          tl: { col: 6, row: rowIndex - 1 }, // rowIndex - 1 是因为 addRow 后 rowIndex 会增加
          ext: { width: 70, height: 60 }, // 宽度和高度应与列宽和行高相适应
        });
      }

      workbook.xlsx.writeBuffer().then((data) => {
        const blob = new Blob([data], {
          type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        });
        saveAs(blob, "DataWithImages.xlsx");
      });
    },

    updateOrder(updateDate) {
      this.loading = true;
      productionOrderUpdate(updateDate)
        .then((response) => {
          this.$message.success("更新成功");
          // 更新原始追踪号
          // record.original_bm_remark = record.bm_remark;
        })
        .catch((error) => {
          this.$message.error("更新失败");
        })
        .finally(() => {
          this.loading = false;
        });
    },
    handleSave(index, record) {
      // 这里可以添加代码，发送修改后的数据到后台进行保存
      if (record.bm_remark === record.original_bm_remark) {
        // 如果追踪号未变化，则不更新
        console.log("备注没有变化，无需更新");
        return;
      }
      const updateDate = { id: record.id, bm_remark: record.bm_remark }
      this.updateOrder(updateDate);
     
    },

    startEdit(index) {
      this.editIndex = index;
      this.editDate = this.currentPageData[index].handle_date;
    },
    saveEdit(record, index) {
      this.editIndex = -1;
      // 可以在这里调用API保存数据
      const updateDate = { id: record.id, handle_date: this.editDate }
      this.updateOrder(updateDate)
      this.currentPageData[index].handle_date = this.editDate;

    },
    cancelEdit() {
      this.editIndex = -1;
    },
    onDateChange(value) {
      this.editDate = value;
    }
  },
  mounted() {
    this.initialize().then(() => {
      this.$nextTick(() => {
        this.addScrollListener();
        this.setScrollPosition();
      });
    });
  },

  beforeDestroy() {
    const tableBody =
      this.$refs.tableContainer.$el.querySelector(".ant-table-body");
    if (tableBody) {
      tableBody.removeEventListener("scroll", this.handleScroll);
    }
  },
};
</script>

<style>
.a4-modal .ant-modal {
  max-width: 794px !important; /* A4 width in pixels */
  width: auto !important;
  height: auto !important; /* Adjust height as necessary */
}

/* 设置屏幕显示样式 */
.production-task-table {
  width: 100%;
  border-collapse: collapse;
}

.production-task-table th,
.production-task-table td {
  border: 1px solid black;
  padding: 8px;
  text-align: center;
  font-size: 10pt; /* Adjust font size for better fit */
}

.production-task-table img {
  width: 50px; /* 控制图片大小 */
}

/* 打印设置 */
@media print {
  body * {
    visibility: hidden;
  }

  /* 只显示需要打印的部分，确保它是可见的 */
  .print-content * {
    visibility: visible;
  }

  .print-content {
    position: absolute;
    left: 0;
    top: 0;
  }

  .production-task-table {
    width: 100%; /* 宽度可能需要调整以适应A4纸 */
    border-collapse: collapse;
  }

  .production-task-table th,
  .production-task-table td {
    font-size: 10pt; /* Adjust font size for print */
    border: 1px solid black; /* 保持边框清晰 */
    padding: 8pt; /* Reduce padding */
    text-align: center; /* 或者根据需要调整对齐方式 */
  }

  .production-task-table img {
    width: 40px; /* Reduce image size for print */
  }
}
</style>
