<template>
  <div>
    <a-card title="员工管理">
      <a-row gutter="16">
        <a-col :span="24" :md="8" :xl="6" style="margin-bottom: 12px">
          <a-input-search v-model="searchForm.search" placeholder="用户名, 名称, 电话" allowClear @search="search" />
        </a-col>

        <div style="margin-bottom: 12px; float: right">
          <a-button
            type="primary"
            icon="plus"
            style="margin: 0 8px"
            @click="openFormModal(form);
            "
          >
            新增员工</a-button
          >
        </div>
      </a-row>

      <a-row style="margin-top: 12px">
        <a-table
          rowKey="id"
          size="small"
          :columns="columns"
          :dataSource="items"
          :loading="loading"
          :pagination="pagination"
          @change="tableChange"
        >
          <div slot="is_active" slot-scope="value">
            <template v-if="value">
              <a-badge status="success" />
              <span>启用</span>
            </template>
            <template v-else>
              <a-badge status="error" />
              <span>禁用</span>
            </template>
          </div>
          <div slot="action" slot-scope="value, item">
            <a-button-group>
              <a-button
                icon="edit"
                size="small"
                @click="openFormModal(item)"
                >编辑</a-button
              >
            </a-button-group>
          </div>
     
        </a-table>
      </a-row>
    </a-card>

    <form-modal 
      ref="childRef"
      v-model="visible"
      :form="targetItem"
      @create="create"
      @update="update" 
    />

  </div>
</template>

<script>
import { userList } from "@/api/hrms.js";
import { getEmployeesNumber } from '@/api/data'
import { permissions } from "@/permissions.js";
import columns from "./columns.js";

export default {
  name: "Account",
  components: {
    FormModal: () => import("./FormModal.vue"),
  },
  data() {
    return {
      columns,
      permissions,
      searchForm: { search: "", page: 1, ordering: undefined, page_size: 16 },
      pagination: { current: 1, total: 0, pageSize: 16 },
      loading: false,
      items: [],
      roleItems: [],
      visible: false,
      targetItem: {},
      form: {},
      
    };
  },
  methods: {
    initialize() {
      this.list();

     
    },
    list() {
      this.loading = true;
      userList(this.searchForm)
        .then((data) => {
          this.pagination.total = data.count;
          this.items = data.results;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    create(item) {
      // this.items.splice(0, 0, item);
      this.list();
    },
    update(item) {
      this.items.splice(
        this.items.findIndex((i) => i.id == item.id),
        1,
        item
      );
    },
    openFormModal(item) {
        if (!item.id) {
          getEmployeesNumber().then(data => {
            this.targetItem = { ...item, ...{ number: data.number, image_items: [] },'is_active': false };
          })
        } else {
          this.targetItem = { ...item, ...{image_items: item.employee_document_items.map(item => {
            return {
              uid: item.id,
              name: item.name,
              status: 'done',
              url: item.file,
              response: {
                id: item.id
              }
            }
          })} };
        }
      
       
        
        this.visible = true;
      },
    search() {
      this.searchForm.page = 1;
      this.pagination.current = 1;
      this.list();
    },
    tableChange(pagination, filters, sorter) {
      this.searchForm.page = pagination.current;
      this.pagination.current = pagination.current;
      this.searchForm.ordering = `${sorter.order == "descend" ? "-" : ""}${sorter.field}`;
      this.list();
    },
    
  },
  mounted() {
    this.initialize();
  },
};
</script>

<style scoped></style>
