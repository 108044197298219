<template>
  <div>
    <a-modal
      v-model="visible"
      width="750px"
      :confirmLoading="loading"
      :maskClosable="false"
      @cancel="cancel"
      @ok="confirm"
    >
      <div slot="title">{{ form.id ? "编辑信息" : "新增考勤信息" }}</div>
      <a-form-model
        ref="form"
        :model="form"
        :rules="rules"
        :label-col="{ span: 4, md: 8 }"
        :wrapper-col="{ span: 20, md: 16 }"
      >
        <a-row gutter="12">
          <a-col :span="24" :md="24" style="margin-left: -120px">
            <a-form-model-item prop="selectedEmployeeIds" label="选择员工">
              <a-select
                show-search
                allowClear
                mode="multiple"
                v-model="form.selectedEmployeeIds"
                style="width: 100%"
                placeholder="请选择员工"
                option-filter-prop="children"
                @change="handleChange"
              >
                <a-select-option
                  v-for="item in employeeItems"
                  :key="item.id"
                  :value="item.id"
                >
                  {{ item.name }}
                </a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="24" style="margin-left: -120px">
            <a-form-model-item prop="work_type" label="类型">
              <a-select
                v-model="form.work_type"
                :disabled="this.currentTab === 'temporary'"
                placeholder="选择类型"
              >
                <a-select-option value="Attendance">出勤</a-select-option>
                <!-- 使用 v-if 判断是否渲染其他选项 -->
                <a-select-option value="Absence">缺勤</a-select-option>
              </a-select>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="24" style="margin-left: -120px">
            <a-form-model-item prop="selectedDateItems" label="选择日期">
              <a-select
                v-model="form.selectedDateItems"
                mode="tags"
                style="width: 100%"
                allowClear
                :open="false"
                @click.native="open = true"
                :disabled="this.form.work_type == null"
              ></a-select>

              <!-- 为日期选择器添加 open 和 @openChange 逻辑 -->
              <a-date-picker
                v-show="datePickerShouldOpen"
                @change="handleDateChange"
                :open="datePickerShouldOpen"
                @openChange="handleOpenChange"
                :disabled-date="disabledDate"
              >
              </a-date-picker>
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="salary_day" label="日薪">
              <a-input v-model="form.salary_day" allowClear />
            </a-form-model-item>
          </a-col>

          <a-col :span="24" :md="12">
            <a-form-model-item prop="remark" label="备注">
              <a-input v-model="form.remark" allowClear />
            </a-form-model-item>
          </a-col>
        </a-row>
      </a-form-model>
    </a-modal>
  </div>
</template>


<script>
import { workRecordsCreate } from "@/api/hrms";
import moment from "moment";
import axios from 'axios';

export default {
  name: "FormModal",
  props: ["visible", "employeeItems", "currentTab"],
  model: { prop: "visible", event: "cancel" },
  data() {
    return {
      loading: false,
      selectedEmployeeItems: [],
      holidays: new Set(), // 用于存储节假日数据
      not_holidays: new Set(), // 用于存储非节假日数据
      form: {
        selectedEmployeeIds: [],
        selectedDateItems: [],
        work_type: this.currentTab === "temporary" ? "Attendance" : null,
        salary_day: null,
        remark: null,
      },

      open: false,
      rules: {
        selectedEmployeeIds: [
          { required: true, message: "员工为必填项", trigger: "blur" },
        ],
        // selectedDateItems: [
        //   { required: true, message: "日期为必填项", trigger: "blur" },
        // ],
        work_type: [
          { required: true, message: "类型为必填项", trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    datePickerShouldOpen() {
      // 当 work_type 不为 null 并且 open 为 true 时，返回 true
      return this.form.work_type !== null && this.open;
    },
    computedSalaryDay() {
      if (!this.selectedEmployeeItems.length) {
        return null; // 如果没有选择任何员工，则清空日薪
      }

      const uniqueSalaries = new Set(
        this.selectedEmployeeItems.map((emp) => {
          if (emp.employee_type === "temporary") {
            return emp.salary_day;
          } else if (emp.employee_type === "permanent") {
            if (emp.salary_day) {
              return emp.salary_day;
            }else {
              return (emp.salary_month / 30).toFixed(2);
            }
          }
        })
      );
      console.log(uniqueSalaries);

      // 如果所有选中的员工的日薪都相同，则返回这个日薪，否则返回 null
      return uniqueSalaries.size === 1 ? Array.from(uniqueSalaries)[0] : null;
    },
  },
  watch: {
    computedSalaryDay(newVal) {
      this.form.salary_day = newVal; // 更新表单中的日薪值
    },
  },
  mounted() {
    this.fetchHolidays();
  },
  methods: {
    moment,

    fetchHolidays() {
      const year = new Date().getFullYear();  // 获取当前年份
      axios.get(`https://timor.tech/api/holiday/year/${year}`)  // 使用模板字符串插入年份
        .then(response => {
          if (response.data && response.data.holiday) {
            // 处理数据，确保你访问正确的属性
            Object.keys(response.data.holiday).forEach(date => {
              if (response.data.holiday[date].holiday) {
                this.holidays.add(response.data.holiday[date].date);
              }
              if (!response.data.holiday[date].holiday) {
                this.not_holidays.add(response.data.holiday[date].date);
              }
            });
          }
        })
        .catch(error => {
          console.error("Failed to fetch holidays:", error);
        });
    },

    disabledDate(current) {
      const day = current.day(); // 获取当前日期是周几，0 是星期天，6 是星期六
      let disabled_date = false;
      const currentDateStr = current.format('YYYY-MM-DD');
      // 判断临时工出勤，禁止选择未来的日期
      if (
        this.currentTab === "temporary" &&
        this.form.work_type === "Attendance"
      ) {
        disabled_date = current > moment().endOf("day");
      }

      // 判断正式工缺勤，禁止选择星期六和星期天以及未来的日期
      if (
        this.currentTab === "permanent" &&
        this.form.work_type === "Absence"
      ) {
        // disabled_date =
        //  day === 0 || day === 6 || current > moment().endOf("day");
        disabled_date =((day === 0 || day === 6) && !this.not_holidays.has(currentDateStr)) || current > moment().endOf("day");
      }

      // 判断正式工加班，禁止选择星期一到星期五中的非节假日以及未来的日期
      if (this.currentTab === "permanent" && this.form.work_type === "Attendance") {
        disabled_date = (day >= 1 && day <= 5 && !this.holidays.has(currentDateStr)) || current > moment().endOf("day");
      }
      console.log('disabled_date',current);
      return disabled_date;
    },

    handleDateChange(date, dateString) {
      if (dateString && !this.form.selectedDateItems.includes(dateString)) {
        this.form.selectedDateItems.push(dateString);
      }
      this.open = true; // 关闭日期选择器
    },

    handleOpenChange(status) {
      this.open = status; // 更新日期选择器的开放状态
    },

    handleChange(value) {
      this.form.selectedEmployeeIds = value;
      this.selectedEmployeeItems = this.employeeItems.filter((item) =>
        this.form.selectedEmployeeIds.includes(item.id)
      );
      console.log(`selected ${value}`);
    },

    confirm() {
      let formatData = {
        ...this.form,
      };
      if (this.form.selectedDateItems.length == 0) {
        this.$message.error("请选择日期");
        return
      }
      
      console.log(formatData);
      this.$refs.form.validate((valid) => {
        if (valid) {
          this.loading = true;
          workRecordsCreate(formatData)
            .then((data) => {
              this.$message.success("新增成功");
              this.$emit("create", data);
              this.cancel();
            })
            .catch((err) => {
              // 判断错误是否有响应对象
              if (err.response) {
                // 处理包含错误的响应数据
                const errorMessage = JSON.stringify(err.response.data, null, 2); // `null, 2` 用于美化格式，2表示缩进为2个空格
                this.$message.error(errorMessage);
              } else {
                // 处理其他类型的错误（例如网络错误）
                this.$message.error("请求错误");
              }
            })
            .finally(() => {
              this.loading = false;
            });
        }
      });
    },

    cancel() {
      this.$emit("cancel", false);
      this.$refs.form.resetFields();
    },
  },
};
</script>

<style scoped>
</style>